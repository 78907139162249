import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { stylesReactSelect } from "./styles";

const Select = ({
  control,
  name,
  options,
  isClearable,
  handleChange,
  controlled,
  value,
  className,
  disabled,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...rest } }) => {
        return (
          <ReactSelect
            options={options}
            onChange={(e) => {
              if (handleChange) return handleChange(e);
              else return onChange(e?.value);
            }}
            value={value}
            className={className ? className : ""}
            isClearable={isClearable}
            placeholder="Seleccionar"
            isOptionDisabled={(option) =>
              disabled && option.disabled === "true"
            }
            styles={stylesReactSelect}
          />
        );
      }}
    />
  );
};

export default Select;
