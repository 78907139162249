import React, { useEffect, useState } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from "react-redux";

import { getGrades } from '../../redux/actionsAsync';

import { useRef } from 'react';
import TurnsTable from '../../components/turnsTable/TurnsTable';
import ReservesTable from '../../components/reservesTable';

import ReservesFilter from '../../components/reservesFilter';
import { Divider } from 'antd';
import ActivitiesTable from '../../components/activitiesTable';
import Calendar from '../../components/calendar';

import GradesTable from '../../components/gradesTable';
import HistoryTable from '../../components/recordsTable';

import WeekTable from '../../components/weekTable';
import NewTurns from '../../components/newTurns';

import PreviewCalendar from '../../components/previewCalendar';
import TurnsAdmin from '../../components/turnsAdmin';

import "../../assets/styles/Admin.css";


const Admin = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [key, setKey] = useState('calendar');

  const submitRef = useRef();

  useEffect(() => {
    dispatch(getGrades());
  }, [dispatch]);


  return (
    <div>
      <div className='container-fluid admin-container'>
        <Card>
          <Card.Body>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 fs-5"
            >
              <Tab eventKey="calendar" title="Calendario">
                {key === "calendar" ? (
                  <Calendar />
                ) : ""}
              </Tab>
              <Tab eventKey="reservas" title="Lista de reservas">
                {key === "reservas" ?
                  <>
                    <ReservesFilter setData={setData} submitRef={submitRef} />
                    <Divider className='fs-5'>Lista de reservas</Divider>
                    <ReservesTable
                      setData={setData}
                      data={data}
                      submitRef={submitRef}
                    />
                  </>
                  : ""}
              </Tab>
              <Tab eventKey="new-turn" title="Crear turnos">
                {key === "new-turn" ? (
                  <NewTurns />
                ) : ""}
              </Tab>
              <Tab eventKey="prevcalendar" title="Vista previa del Calendario">
                {key === "prevcalendar" ? (
                  <PreviewCalendar />
                ) : ""}
              </Tab>
              <Tab eventKey="turns" title="Gestión de Turnos">
                {key === "turns" ? (
                  <TurnsAdmin />
                ) : ""}
              </Tab>
              <Tab eventKey="dates" title="Administrar fechas y horarios">
                {key === "dates" ? (
                  <TurnsTable />
                ) : ""}
              </Tab>
              <Tab eventKey="activities" title="Administrar actividades">
                {key === "activities" ?
                  (<ActivitiesTable />
                  ) : ""}
              </Tab>
              <Tab eventKey="grades" title="Administrar Grados">
                {key === "grades" ? (
                  <GradesTable />
                ) : ""}
              </Tab>
              <Tab eventKey="week" title="Administrar días de las actividades">
                {key === "week" ? (
                  <WeekTable />
                ) : ""}
              </Tab>
              <Tab eventKey="history" title="Registros">
                {key === "history" ? (
                  <HistoryTable />
                ) : ""}
              </Tab>
            </Tabs>

          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Admin