import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useUser from "./useUser";
import { axiosTurnosAPI } from "../api/axios";


const useAxiosTurnosAPI = () => {
    const navigate = useNavigate();
    const user = useUser();
    const token = user ? user.token : undefined;

    useEffect(() => {
        const requestIntercept = axiosTurnosAPI.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        return () => {
          axiosTurnosAPI.interceptors.request.eject(requestIntercept);
        }
    }, [token , navigate])

    return axiosTurnosAPI;
}

export default useAxiosTurnosAPI;