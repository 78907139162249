import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/static/images/sn-logo.png";

import useUser from "../../hooks/useUser";
import { logout } from "../../services/auth";
import LoginModal from "../loginModal/LoginModal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import LoadingModal from "../loadingModal/LoadingModal";

import "../../assets/styles/Header.css";
import { Icon } from "../icon";
import { Button } from "react-bootstrap";

const Header = () => {
  const [show, setShow] = useState(false);
  const user = useUser();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.data);
  const location = useLocation();

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <nav className="h-20 px-4 lg:px-5 flex justify-between items-center text-institutional shadow-xl">
      <Link to="/" className="w-fit flex items-center gap-x-5">
        <img src={logo} alt="logo-sn" className="w-12" />
        <div className="w-[1.5px] h-14 bg-blue-500" />
        <p>
          Redescubrí <span className="font-bold">San Nicolás</span>
        </p>
      </Link>

      {!user ? (
        <button onClick={handleOpen}>
          <Icon type="person" className="text-4xl" />
        </button>
      ) : (
        <div className="flex items-center gap-x-5">
          <p>{user.username}</p>
          <div className="w-[1.5px] h-8 bg-blue-500" />
          {location?.pathname !== "/admin" && (
            <Link
              to="/admin"
              className="px-3 py-1.5 text-gray-500 border-2 border-gray-500 rounded-lg"
            >
              Panel de administración
            </Link>
          )}
          <Button
            variant="outline-danger"
            onClick={handleLogout}
            className="px-3 py-1.5 text-red-500 border-2 border-red-500 rounded-lg"
          >
            Cerrar Sesión
          </Button>
        </div>
      )}
      {show ? <LoginModal show={show} handleClose={handleClose} /> : ""}
      {loading ? <LoadingModal show={loading} /> : ""}
    </nav>
  );
};

export default Header;
