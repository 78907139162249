import { Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios, { axiosTurnosAPI } from "../../api/axios";
import { emptyOption } from "../../constants/form";
import ReactSelect from "react-select";
import { stylesReactSelect } from "../select/styles";
import EditTurnDate from "../editTurnDate/EditTurnDate";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getUnidadSemanas } from "../../services/activities";
import { setLoading } from "../../redux/formDataSlice";
import { useDispatch } from "react-redux";

const columns = [
  {
    title: <span className="weektable-th">Actividad</span>,
    dataIndex: "name",
    key: "name",
    render: (e) => {
      return <span className="weektable-activity">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Lunes</span>,
    dataIndex: "Lunes",
    key: "Lunes",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Martes</span>,
    dataIndex: "Martes",
    key: "Martes",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Miércoles</span>,
    dataIndex: "Miercoles",
    key: "Miercoles",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Jueves</span>,
    dataIndex: "Jueves",
    key: "Jueves",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Viernes</span>,
    dataIndex: "Viernes",
    key: "Viernes",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Sábado</span>,
    dataIndex: "Sabado",
    key: "Sabado",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Domingo</span>,
    dataIndex: "Domingo",
    key: "Domingo",
    render: (e) => {
      return <span className="weektable-day">{e}</span>;
    },
  },
  {
    title: <span className="weektable-th">Acciones</span>,
    dataIndex: "acciones",
    key: "acciones",
  },
];

const WeekTable = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [actividades, setActividades] = useState("");
  const [semana, setSemana] = useState([]);
  const [values, setValues] = useState({
    dia: "",
    actividad: "",
  });

  const [show, setShow] = useState(false);
  const [date, setDate] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosTurnosAPI.get("week");

        let format = response.data.map((e) => {
          return {
            ...e,
            value: e.id,
            label: e.dia,
          };
        });

        setSemana(format);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("units");

        let format = response.data.map((e) => {
          return {
            ...e,
            value: e.id,
            label: e.name,
            actividad: true,
          };
        });

        setActividades(format);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(setLoading(true));

        const response = await getUnidadSemanas();

        setData(response);
        dispatch(setLoading(false));
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
      }
    };
    getData();
  }, []);

  // const formatDates = (date) => {
  //   return format(new Date(formatDate(date)), 'EEEE', { locale: es })
  // }

  const handleClick = (row) => {
    setDate(row);
    setShow(true);
  };

  const handleChange = (e) => {
    if (e.actividad) {
      setValues({
        ...values,
        actividad: e.value,
      });
    } else {
      setValues({
        ...values,
        dia: e.value,
      });
    }
  };

  const handleSave = async () => {
    try {
      if (!values.actividad || !values.dia) return;
      dispatch(setLoading(true));

      await axios.post("units/createUnidadSemana", values);

      const response = await getUnidadSemanas();

      setData(response);

      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  const formatData = (array) => {
    try {
      if (array?.length) {
        return array.map((e) => {
          const { Unidad_Semanas } = e;

          let dias = Unidad_Semanas.reduce((prev, current) => {
            return {
              ...prev,
              [current.Semana.dia]: (
                <i className="fa fa-check" style={{ color: "green" }} />
              ),
            };
          }, {});

          return {
            ...e,
            ...dias,
            // [dia]: <div>
            //   <ul>
            //     {Unidad_Semanas?.length ? Unidad_Semanas.map((unidadSemana) => {
            //       return (
            //         <li key={`${dia}-${unidadSemana.idUnidad}`} style={{ cursor: "pointer" }} onClick={() => handleClick(unidadSemana)}>{unidadSemana.Unidade.name}</li>
            //       )
            //     }) : ""}
            //   </ul>
            // </div>,
            acciones: (
              <div>
                <Button
                  variant="outline-primary"
                  onClick={() => handleClick(e)}
                >
                  Cambiar día
                </Button>
              </div>
            ),
          };
        });
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  return (
    <div>
      <div className="my-5 mx-3">
        <h5 className="form-subtitle mb-3">Asignar actividad</h5>
        <Row>
          <Col>
            <Form.Label>Actividad</Form.Label>
            <ReactSelect
              options={
                actividades?.length
                  ? [emptyOption, ...actividades]
                  : [emptyOption]
              }
              styles={stylesReactSelect}
              onChange={handleChange}
              defaultValue={actividades[0]}
            />
          </Col>
          <Col>
            <Form.Label>Día de la semana</Form.Label>
            <ReactSelect
              options={
                semana?.length ? [emptyOption, ...semana] : [emptyOption]
              }
              styles={stylesReactSelect}
              onChange={handleChange}
              defaultValue={semana[0]}
            />
          </Col>
        </Row>
        <div className="text-center mt-3">
          <Button variant="outline-success" onClick={handleSave}>
            Guardar
          </Button>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={formatData(data)}
          rowKey={() => uuidv4()}
        />
      </div>
      {show ? (
        <EditTurnDate
          show={show}
          handleClose={() => setShow(false)}
          date={date}
          setData={setData}
          actividades={actividades}
          semana={semana}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default WeekTable;
