export const stylesReactSelect = {
  control: (styles) => ({
    ...styles,
    height: "50px",
    borderColor: "#687073"
  }),
  valueContainer: (styles) => {
    return {
      ...styles,
      height: "50px",
      padding: "0px 8px",
      paddingTop: "0",
      paddingBottom: "0",
    }
  },
  input: (styles) => {
    return {
      ...styles,
      padding: "0",
      margin: "0"
    }
  },
};