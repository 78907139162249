import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { axiosTurnosAPI } from "../../api/axios";
import { Upload, Button as AntdButton } from "antd";
import { formatLocalDate } from "../../utils/utils";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";

const Confirmacion = () => {
  const { token } = useParams();
  const { loading } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [fileList, setFileList] = useState([]);

  const getData = async () => {
    try {
      dispatch(setLoading(true));
      let response = await axiosTurnosAPI.post(`/reserves/findReserve`, {
        token,
      });

      setData(response.data);
      setLoading(false);

      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, [token]);

  const handleChange = (e) => {
    if (e?.fileList.length) {
      setFileList([e?.fileList[e?.fileList.length - 1]]);
    } else {
      setFileList([]);
    }
  };

  const handleConfirm = async () => {
    try {
      if (!fileList.length) {
        return Swal.fire({
          html: "<div style='font-size: 22; font-weight: 700;'>Debe cargar el permiso</div>",
          icon: "warning",
        });
      }
      dispatch(setLoading(true));
      let newData = new FormData();
      newData.append("token", token);
      newData.append("file", fileList[0].originFileObj);
      let response = await axiosTurnosAPI.post("/reserves/confirm", newData);

      getData();

      Swal.fire({
        title: "",
        text: response?.data?.mensaje,
        icon: response?.data?.confirmado ? "success" : "error",
      });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="seccion">
      <div className="container">
        <div
          className="text-center f-color fw-700"
          style={{ fontSize: 22, marginTop: 40, marginBottom: 40 }}
        >
          Confirmar reserva
        </div>

        {!loading ? (
          data ? (
            <div>
              <div className="d-flex justify-content-center mb-4">
                <div>
                  <div>
                    <div
                      className="fw-700 pb-2 text-center"
                      style={{ fontSize: 18 }}
                    >
                      <span>Datos de la reserva</span>
                    </div>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Fecha:</span>
                    <span>
                      {formatLocalDate(data.Eventos_Apps_SubApp?.fecha)}
                    </span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Horario:</span>
                    <span>{data.Eventos_Apps_SubApp?.horario}</span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Escuela:</span>
                    <span>
                      {
                        data.Agentes_Subagente?.Agente?.agente_entidad?.entidad
                          ?.nombre
                      }
                    </span>
                  </div>
                  <div>
                    <span className="fw-700 me-2">Grado:</span>
                    <span>{data.Agentes_Subagente?.Subagente?.nombre}</span>
                  </div>
                </div>
              </div>

              {data.estado === "PENDIENTE" ? (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center py-5">
                    <div className="mb-3">
                      <span className="fw-700" style={{ fontSize: 16 }}>
                        Adjuntar el permiso de salida educativa
                      </span>
                    </div>
                    <div style={{ width: 400, textAlign: "center" }}>
                      <Upload
                        beforeUpload={() => false}
                        name="files"
                        listType="picture"
                        iconRender={(f) => {
                          if (f.type === "application/pdf") {
                            return (
                              <i
                                className="fa fa-file-pdf-o"
                                style={{ fontSize: 30, color: "#FA0F00" }}
                              ></i>
                            );
                          } else {
                            return (
                              <i
                                className="fa fa-file-o"
                                style={{ fontSize: 30 }}
                              ></i>
                            );
                          }
                        }}
                        fileList={fileList}
                        onChange={handleChange}
                      >
                        <AntdButton
                          icon={
                            <i
                              className="fa fa-download"
                              style={{
                                fontSize: 18,
                                marginRight: 10,
                                marginTop: 2,
                              }}
                            ></i>
                          }
                        >
                          {" "}
                          Subir archivo
                        </AntdButton>
                      </Upload>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      variant="outline-success"
                      onClick={handleConfirm}
                      className="me-3"
                    >
                      Confirmar
                    </Button>
                    <Button variant="outline-danger">Cancelar</Button>
                  </div>
                </>
              ) : (
                <div className="text-center fw-700" style={{ fontSize: 16 }}>
                  El estado actual de su turno es {data.estado}.
                </div>
              )}
            </div>
          ) : (
            <div className="text-center fw-700" style={{ fontSize: 16 }}>
              Link caducado, el tiempo de confirmación finalizó, su turno fue
              cancelado.
            </div>
          )
        ) : (
          <div className="text-center fw-700" style={{ fontSize: 16 }}>
            Cargando...
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmacion;
