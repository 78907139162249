export const columns = {
  reserves: [
    {
      title: 'Escuela',
      dataIndex: 'escuela',
      key: 'escuela',
    },
    {
      title: 'Grado',
      dataIndex: 'grado',
      key: 'grado',
    },
    {
      title: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
    },
    {
      title: 'Fecha del turno',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: 'Horario',
      dataIndex: 'horario',
      key: 'horario',
    },
    {
      title: 'Estudiantes',
      dataIndex: 'personas',
      key: 'personas',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ]
}