import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { axiosTurnosAPI } from "../api/axios";

const submit = createAsyncThunk(
  "formData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosTurnosAPI.post(
        "/reserves/createReserve",
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

const getGrades = createAsyncThunk(
  "formData/grades",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("grades");

      let result = response.data?.map((e) => {
        return {
          label: e.grado,
          value: e.grado,
          ...e,
          ...e.actividades,
        };
      });

      return result;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const getDates = createAsyncThunk(
  "formData/dates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosTurnosAPI.get("/turns/available-dates");

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export { submit, getGrades, getDates };
