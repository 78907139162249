import { useState, useCallback } from "react";
import { Icon } from "../icon";

export const ActivityDropdown = ({
  displayName,
  imgSrc,
  levels,
  maxCapacity,
  duration,
  days,
  schedules,
  description,
  recommendations,
  obraDeTeatro,
  warnning,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = useCallback(
    () => setIsExpanded((currentState) => !currentState),
    []
  );

  return (
    <div className="hover:scale-[1.02] ease-in-out duration-150">
      <button
        onClick={toggleExpansion}
        className="w-full h-24 bg-institutional px-4 flex items-center justify-between text-white outline-none"
      >
        <img src={imgSrc} className="w-20" alt="logo" />
        <p className="ml-5 mr-auto font-bold">{displayName}</p>
        <Icon type={isExpanded ? "expand_less" : "expand_more"} />
      </button>
      {isExpanded && (
        <div className="bg-[#F5F5F5] p-4 flex flex-col gap-y-5">
          <div className="flex gap-x-8">
            <div className="flex items-end gap-x-1">
              <Icon type="schedule" className="text-[#687073]" />
              <p className="font-bold text-institutional">{duration} min.</p>
            </div>
            <div className="flex items-end gap-x-1">
              <Icon type="group" className="text-[#687073]" />
              <p className="font-bold text-institutional">
                {maxCapacity} personas
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="font-bold">¿Para qué nivel?</p>
            <div className="flex gap-x-2.5">
              {levels.map((level, index) => (
                <p
                  key={index}
                  className="w-fit bg-institutional px-2.5 py-0.5 text-white rounded-xl"
                >
                  {level}
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="font-bold">Días</p>
            <div className="flex divide-x-2 divide-institutional font-bold">
              {days.map((day, index) => (
                <p
                  key={index}
                  className="pl-2 first:pl-0 pr-2 last:pr-0 text-institutional uppercase"
                >
                  {day}
                </p>
              ))}
              {warnning && (
                <span className="pl-2 first:pl-0 pr-2 last:pr-0 text-red-600 uppercase">
                  - {warnning}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="font-bold">Horarios</p>
            <div className="flex gap-2.5 flex-wrap">
              {schedules.map((schedule, index) => (
                <p
                  key={index}
                  className="w-fit px-2.5 py-0.5 text-institutional font-bold border-2 border-institutional rounded-xl"
                >
                  {schedule}
                </p>
              ))}
            </div>
          </div>
          <hr />
          <div className="flex flex-col gap-y-2.5">
            <p className="text-institutional font-bold">¿De que se trata?</p>
            <p className="text-[#687073]">{description}</p>
            <p className="text-institutional font-bold">Recomendaciones</p>
            <p className="text-[#687073]">{recommendations}</p>
          </div>
          {obraDeTeatro && (
            <>
              <div className="w-full h-10 bg-institutional px-4 flex items-center justify-between text-white outline-none">
                <img
                  src={obraDeTeatro.imgSrc}
                  alt="obra de teatro"
                  className="w-20"
                />
                <p className="ml-5 mr-auto font-bold">
                  {obraDeTeatro.displayName}
                </p>
              </div>
              <div className="flex gap-x-8">
                <div className="flex items-end gap-x-1">
                  <Icon type="schedule" className="text-[#687073]" />
                  <p className="font-bold text-institutional">
                    {obraDeTeatro.duration} min.
                  </p>
                </div>
                <div className="flex items-end gap-x-1">
                  <Icon type="group" className="text-[#687073]" />
                  <p className="font-bold text-institutional">
                    {obraDeTeatro.maxCapacity} personas
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="font-bold">¿Para qué nivel?</p>
                <div className="flex gap-x-2.5">
                  {obraDeTeatro.levels.map((level, index) => (
                    <p
                      key={index}
                      className="w-fit bg-institutional px-2.5 py-0.5 text-white rounded-xl"
                    >
                      {level}
                    </p>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-y-1">
                <p className="font-bold">Días</p>
                <div className="flex divide-x-2 divide-institutional font-bold">
                  {obraDeTeatro.days.map((day, index) => (
                    <>
                      <p
                        key={index}
                        className="pl-2 first:pl-0 pr-2 last:pr-0 text-institutional uppercase"
                      >
                        {day}{" "}
                        {obraDeTeatro.warnning && (
                          <span className="pl-2 first:pl-0 pr-2 last:pr-0 text-red-600 uppercase">
                            - {obraDeTeatro.warnning}
                          </span>
                        )}
                      </p>
                    </>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="font-bold">Horarios</p>
                <div className="flex gap-2.5 flex-wrap">
                  {obraDeTeatro.schedules.map((schedule, index) => (
                    <p
                      key={index}
                      className="w-fit px-2.5 py-0.5 text-institutional font-bold border-2 border-institutional rounded-xl"
                    >
                      {schedule}
                    </p>
                  ))}
                </div>
              </div>
              <hr />
              <div className="flex flex-col gap-y-2.5">
                <p className="text-institutional font-bold">
                  ¿De que se trata?
                </p>
                <p className="text-[#687073]">{obraDeTeatro.description}</p>
                <p className="text-institutional font-bold">Recomendaciones</p>
                <p className="text-[#687073]">{obraDeTeatro.recommendations}</p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
