import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import TurnForm from "../../components/turnForm";
import Representative from "../../components/representative";
import School from "../../components/school";

import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getGrades, submit } from "../../redux/actionsAsync";
import { validation } from "../../utils/validation";

import LoadingModal from "../../components/loadingModal/LoadingModal";

import { Steps } from "antd";

import "../../assets/styles/Forms.css";
import { ActivityDropdown } from "../../components/activityDropdown";
import { Icon } from "../../components/icon";

const { Step } = Steps;

const FormPage = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validation),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      otro_school: true,
    },
  });

  const watchQtyStudents = watch("estudiantes");
  const { status } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [current, setCurrent] = useState(0);
  const [check, setCheck] = useState(false);

  const activitiesDropDown = useMemo(
    () => [
      {
        displayName: "Visita al Estadio",
        imgSrc: "/imgs/estadio.svg",
        levels: ["Primario", "Secundario"],
        maxCapacity: 35, // people
        duration: 60, // mins
        days: ["LUNES", "MARTES", "MIERCOLES", "JUEVES"],
        schedules: ["10:00", "14:00"],
        description:
          "La visita al Estadio San Nicolás ofrece una recorrida única por todos los espacios destinados tanto al público como a los protagonistas de un evento deportivo, pasando por los vestuarios, los palcos de prensa y el campo de juego, donde tendrán una propuesta de actividad lúdica. La visita inicia en el ingreso principal por calle Andrés del Pozo y finaliza en la salida de calle José Rucci.",
        recommendations: "¡LLEVAR ROPA CÓMODA Y BOTELLA DE AGUA!",
      },
      {
        displayName: "Visita al Ecoparque",
        imgSrc: "/imgs/ecoparque.svg",
        levels: ["Inicial", "Primario", "Secundario"],
        maxCapacity: 35, // people
        duration: 60, // mins
        days: ["MARTES", "MIÉRCOLES", "JUEVES"],
        schedules: ["10:00", "14:00"],
        description:
          "La visita al Eco Parque ofrece una conexión única con nuestra flora y fauna autóctona, recorriendo espacios característicos de este pulmón verde que posee nuestra ciudad. Tendrán la posibilidad de convertirse en verdaderos exploradores y descubrir los secretos que la naturaleza tiene para mostrarnos.",
        // El recorrido comienza: - PRIMARIA: En el conteiner de Eco Parque que se ubica dentro del predio junto al estacionamiento y el parque de juegos. (Recorrido a pie). - SECUNDARIA: En la Estación Descubrí, oficina de turismo que se encuentra en Av. Viale y Paseo Costanero. (Recorrido a pie).",
        // warnning: "DISPONIBLE A PARTIR DE SEPTIEMBRE",
        recommendations: "¡LLEVAR ROPA CÓMODA Y BOTELLA DE AGUA!",
      },
      // {
      //   displayName: "Bus Turístico",
      //   imgSrc: "/imgs/colectivo.svg",
      //   levels: ["Primario", "Secundario"],
      //   maxCapacity: 23, // people
      //   duration: 60, // mins
      //   days: ["LUNES", "MARTES", "MIÉRCOLES", "JUEVES"],
      //   schedules: ["10:00", "14:00"],
      //   description:
      //     "La excursión en Bus turístico comprende un recorrido por el casco céntrico de la ciudad contemplando las fachadas de los edificios más icónicos de la historia de San Nicolás, conociendo los mitos y las leyendas que los envuelven. Además, los alumnos podrán descender en nuestra querida Plaza Mitre y apreciar dos de los murales más imponentes del arte urbano de la ciudad. La visita inicia en la parada del Bus , ubicada sobre el Colegio Nacional en la esquina de Guardia Nacional y Mitre.",
      //   recommendations: "¡LLEVAR ROPA CÓMODA!",
      // },
      {
        displayName: "Visita al Teatro",
        imgSrc: "/imgs/teatro.svg",
        levels: ["Primario", "Secundario"],
        maxCapacity: 35, // people
        duration: 60, // mins
        days: ["MARTES", "MIÉRCOLES", "JUEVES"],
        schedules: ["10:00", "14:00"],
        description: `La visita al Teatro San Nicolás permite vivir una experiencia única en uno de los edificios más emblemáticos de la ciudad, empaparse con su historia y conocer cada uno de sus rincones. ¿Cuándo se fundó? ¿Quién fue el encargado de las hermosas pinturas? ¿Qué hay detrás del escenario?.
        ¡Averígüenlo en esta visita imperdible!
        Además, el recorrido incluye la nueva sala interactiva en el foyer.`,
        obraDeTeatro: {
          displayName: "Obra de Teatro",
          imgSrc: "/imgs/obra.svg",
          levels: ["Inicial"],
          maxCapacity: 280, // people
          duration: 30, // mins
          days: ["ÚLTIMO LUNES DE CADA MES"],
          // warnning: "DISPONIBLE A PARTIR DE MAYO",
          schedules: ["10:00", "14:00"],
          description:
            "En esta ocasión podremos disfrutar de la obra 'Mis amigos y el teatro'. Cuatro amigos se sumergen en la asombrosa aventura de crear una obra de teatro usando la imaginación para los personajes y un hermoso escenario, sin dejar de divertirse y aprender. ¿Nos acompañás?",
          recommendations: "¡LLEVAR ROPA CÓMODA!",
        },
        recommendations: "¡LLEVAR ROPA CÓMODA!",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(getGrades());
  }, [dispatch]);

  function next(e) {
    let values = getValues();
    if (current === 0) {
      setCheck(true);
      if (
        !values.name ||
        !values.lastname ||
        !values.dni ||
        !values.mobilephone ||
        !values.email ||
        !values.cargo
      ) {
        if (!values.name) {
          setError("name", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }
        if (!values.lastname) {
          setError("lastname", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }
        if (!values.dni) {
          setError("dni", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }
        if (!values.mobilephone) {
          setError("mobilephone", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }
        if (!values.email) {
          setError("email", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }
        if (!values.cargo) {
          setError("cargo", {
            type: "custom",
            message: "Este campo es obligatorio",
          });
        }

        return;
      } else if (
        errors.name ||
        errors.lastname ||
        errors.dni ||
        errors.mobilephone ||
        errors.email ||
        errors.cargo
      ) {
        return;
      } else {
        setCheck(false);
        setCurrent(current + 1);
      }
    } else if (current === 1) {
      setCheck(true);
      if (!values.name_school || values.name_school === "Seleccionar") {
        setError("name_school", {
          type: "custom",
          message: "Este campo es obligatorio",
        });
        return;
      }
      //else if (values.name_school) {
      //  return;
      //}
      else {
        setCheck(false);
        setCurrent(current + 1);
      }
    }
  }

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSend = (values) => {
    setLoading(true);
    if (values.id_turn === values.id_turn_2) {
      setLoading(false);
      return Swal.fire({
        title: "Error!",
        text: "Los turnos no deben ser iguales, seleccione diferente día y horario.",
        icon: "error",
      });
    }
    dispatch(submit(values))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setLoading(false);
          return Swal.fire({
            title: "¡Solicitud enviada!",
            text: "Los turnos se solicitaron exitosamente. En los próximos días recibirás su confirmación.",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              //mejorar limpieza de estados
              window.location.reload();
            }
          });
        } else {
          setLoading(false);
          return Swal.fire({
            title: "¡Error!",
            text: `${
              response.payload.error
                ? response.payload.error
                : "Ocurrio un error, intentelo nuevamente"
            }`,
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              //mejorar limpieza de estados
              window.location.reload();
            }
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error, intentelo nuevamente",
          icon: "error",
          //willClose: () => window.location.reload(),
        }).then((result) => {
          if (result.isConfirmed) {
            //mejorar limpieza de estados
            window.location.reload();
          }
        });
      });
  };

  const steps = [
    {
      title: "Paso 1",
      content: (
        <Representative
          register={register}
          control={control}
          errors={errors}
          check={check}
        />
      ),
    },
    {
      title: "Paso 2",
      content: (
        <School
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
        />
      ),
    },
    {
      title: "Paso 3",
      content: (
        <TurnForm
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          check={check}
          watchQtyStudents={watchQtyStudents}
        />
      ),
    },
  ];

  return (
    <main className="px-3 py-5 flex flex-col gap-y-10">
      {/* Activities */}
      <section className="lg:mx-96 flex flex-col gap-y-4">
        <p className="mb-3 text-center text-2xl font-bold text-institutional">
          Actividades disponibles
        </p>
        {activitiesDropDown.map((activityProps, index) => (
          <ActivityDropdown key={index} {...activityProps} />
        ))}
      </section>

      {/* Advices */}
      <section className="lg:mx-96 flex flex-col gap-y-5">
        <div className="flex justify-center items-center gap-x-2.5 text-xl text-red-600">
          <Icon type="error" className="text-3xl" />
          <p className="font-bold uppercase">A tener en cuenta: </p>
        </div>

        <div className="bg-red-600 px-4 py-3 text-white text-center rounded-xl">
          <p>
            48 horas antes de la actividad el colegio deberá presentar la salida
            educativa, el seguro o la autorización. Si no se presenta la
            documentación la visita se{" "}
            <span className="font-bold">dará automáticamente de baja</span>.
          </p>
        </div>

        <div className="bg-red-600 px-4 py-3 text-white text-center rounded-xl">
          <p className="font-bold">
            El transporte de pasajeros entre la institución y el punto de
            encuentro de cada actividad no se encuentra incluído en el programa.
          </p>
        </div>

        {/* <div className="bg-red-600 px-4 py-3 text-white text-center rounded-xl">
          <p className="font-bold">
            Para las escuelas que no pertenecen a San Nicolás, las excursiones
            tendrán un costo por alumno, por excursión, de $2000 (pesos dos
            mil).
          </p>
        </div> */}

        <p className="text-center text-institutional">
          Ante cualquier duda o consulta comunicáte por email a{" "}
          <a
            href="mailto:snmodoexcursion@sannicolas.gob.ar"
            className="font-bold underline underline-institutional underline-offset-4"
          >
            snmodoexcursion@sannicolas.gob.ar
          </a>
          .
        </p>
      </section>

      <div className="form-container p-3">
        <div className="form-title">Registrate para reservar una actividad</div>

        <div className="container form">
          <Form onSubmit={handleSubmit(handleSend)}>
            <Steps current={current} labelPlacement="vertical">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>

            <div>
              {steps.map(({ title, content }, i) => (
                <div key={title}>{current === i && content}</div>
              ))}
            </div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  onClick={() => prev()}
                  style={{ margin: "0 8px" }}
                  className="prev-button"
                >
                  Anterior
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button onClick={() => next()} className="next-button">
                  Siguiente
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  variant="success"
                  type="submit"
                  className="next-button"
                  disabled={isSubmitting || loading}
                  hidden={watch("actividades") === "OBRA DE TEATRO"}
                >
                  {loading ? "Enviando" : "Finalizar"}
                </Button>
              )}
            </div>
          </Form>
        </div>
        {loading ? <LoadingModal show={status === "loading"} /> : ""}
      </div>
    </main>
  );
};

export default FormPage;
