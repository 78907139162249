export const formatLocalDate = (date) => {
  if (date) {
    let [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`
  } else {
    return date;
  }
}

export const formatDate = (date) => {
  if (date) {
    let [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`
  } else {
    return date;
  }
}

export const formatDBDate = (date) => {
  if (date) {
    let [year, month, day] = date.split("T")[0].split("-");
    return `${month}/${day}/${year}`
  } else {
    return date;
  }
}

export const formatLocalDBDate = (date) => {
  if (date) {
    let [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`
  } else {
    return date;
  }
}

export const formatByNewDate = (date) => {
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  if (month < 10) {
    if (day < 10) {
      return `0${day}/0${month}/${year}`
    } else {
      return `${day}/0${month}/${year}`
    }
  } else {
    if (day < 10) {
      return `0${day}/${month}/${year}`
    } else {
      return `${day}/${month}/${year}`
    }
  }
}
export const comparateDates = (date, availableDates) => {
  if (date && availableDates?.length) {
    let getDate = availableDates.find(e => new Date(formatDate(e))?.getTime() === new Date(date)?.getTime());

    if (!getDate) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }
};

export const formatNewDate = (date) => {
  if (date) {
    let [year, month, day] = date.split("T")[0].split("-");
    return new Date(`${month}/${day}/${year}`)
  } else {
    return date;
  }
}

export const sortArray = (array) => {
  if (array?.length) {
    return array.sort((a, b) => {
      let c = a?.horario?.split(":")[0];
      let d = b?.horario?.split(":")[0];

      if (+c > +d) {
        return 1;
      }
      if (+c < +d) {
        return -1;
      }
      return 0;
    });
  } else {
    return [];
  }
}

export const sortHoraries = (array) => {
  if (array?.length) {
    return array.sort((a, b) => {
      let c = a?.split(":")[0];
      let d = b?.split(":")[0];

      if (+c > +d) {
        return 1;
      }
      if (+c < +d) {
        return -1;
      }
      return 0;
    });
  } else {
    return [];
  }
}

export const uppercaseFirstLetter = (string) => {
  if (string) {

    let array = string?.toLowerCase().split(" ");

    array[0] = array[0][0].toUpperCase() + array[0].substring(1, array[0].length)
    let result = array.join(" ");

    return result;
  }
  else {
    return "";
  }
}


export const comparateAllDates = (date, availableDates) => {
  if (date && availableDates?.length) {
    let getDate = availableDates.find(e => new Date(formatDate(e?.fecha))?.getTime() === new Date(date)?.getTime());
    if (!getDate) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
};

export const setDatesClassName = (date, availableDates) => {
  if (date && availableDates?.length) {
    let getDate = availableDates.find(e => new Date(formatDate(e?.fecha))?.getTime() === new Date(date)?.getTime());

    if (getDate) {
      if (availableDates.some(e => (e.fecha === getDate.fecha) && e.enabled) && availableDates.some(e => (e.fecha === getDate.fecha) && !e.enabled)) {
        return "date-both";
      }
      else if (availableDates.some(e => (e.fecha === getDate.fecha) && e.enabled) && !availableDates.some(e => (e.fecha === getDate.fecha) && !e.enabled)) {
        return "date-enabled";
      }
      else {
        return "date-disabled";
      }
    }
    else {
      return "";
    }
  }
  else {
    return false;
  }
};


export const getMondays = () => {
  var d = new Date(), //Start today
    year = d.getFullYear(),
    mondays = [];

  // Starts on the first day of the month
  // d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
    d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getFullYear() === year) {
    let pushDate = new Date(d.getTime());
    //yyyy/mm/d
    mondays.push(pushDate.getFullYear() + '/' + (pushDate.getMonth() + 1) + '/' + pushDate.getDate());
    d.setDate(d.getDate() + 7);
  }

  if (!mondays.length) {
    while (d.getFullYear() === year + 1) {
      let pushDate = new Date(d.getTime());
      //yyyy/mm/d
      mondays.push(pushDate.getFullYear() + '/' + (pushDate.getMonth() + 1) + '/' + pushDate.getDate());
      d.setDate(d.getDate() + 7);
    }
  }

  return mondays;
}