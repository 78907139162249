import React, { useEffect } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

const SchoolForm = ({ schoolData, handleSubmitSchool, setSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (setSubmit) {
      setSubmit(() =>
        handleSubmit((formData) => {
          const hasErrors = Object.keys(errors).length > 0;
          if (hasErrors) {
            return false; // Retorna false si hay errores, para evitar cerrar el modal
          }
          return handleSubmitSchool({ ...formData, ...schoolData });
        })
      );
    }
  }, [handleSubmit, handleSubmitSchool, setSubmit, schoolData, errors]);

  return (
    <Form
      onSubmit={handleSubmit((formData) =>
        handleSubmitSchool({ ...formData, ...schoolData })
      )}
      className="p-8"
    >
      <div className="forms">
        <Row className="mb-3">
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Nombre de escuela</Form.Label>
              <Form.Control
                name="nombre"
                {...register("nombre", {
                  required: "El nombre es requerido",
                })}
                isInvalid={!!errors.nombre} // Clase de Bootstrap para error
              />
              <Form.Control.Feedback type="invalid">
                {errors.nombre && errors.nombre?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                name="direccion"
                {...register("direccion", {
                  required: "La dirección es requerida",
                })}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback type="invalid">
                {errors.direccion?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                name="phone"
                {...register("phone", {
                  required: "El teléfono es requerido",
                })}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Correo institucional</Form.Label>
              <Form.Control
                name="mail"
                {...register("mail", { required: "El email es requerido" })}
                isInvalid={!!errors.mail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mail?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Director</Form.Label>
              <Form.Control
                name="director"
                {...register("director", {
                  required: "El director es requerido",
                })}
                isInvalid={!!errors.director}
              />
              <Form.Control.Feedback type="invalid">
                {errors.director?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label>Tipo de Escuela</Form.Label>
              <FormCheck
                type="radio"
                label="Privada"
                value="privada"
                isInvalid={!!errors.tipoEscuela}
                {...register("tipoEscuela", {
                  required: "Debe seleccionar si es privada o pública",
                })}
              />
              <FormCheck
                type="radio"
                label="Pública"
                value="publica"
                isInvalid={!!errors.tipoEscuela}
                {...register("tipoEscuela", {
                  required: "Debe seleccionar si es privada o pública",
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tipoEscuela?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default SchoolForm;
