import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { Calendar } from "react-multi-date-picker";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import LoadingModal from "../loadingModal/LoadingModal";
import { avariableHorary, months, weekDays } from "../../constants/form";
import { formatLocalDate } from "../../utils/utils";

import { v4 as uuidv4 } from "uuid";

import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import "./NewTurns.css";
import { axiosTurnosAPI } from "../../api/axios";

const NewTurns = () => {
  const user = useUser();

  const [dates, setDates] = useState();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);

  const [showHoraries, setShowHoraries] = useState(avariableHorary);
  const [selects, setSelects] = useState([]);
  const [horaries, setHoraries] = useState([]);
  const [restricciones, setRestricciones] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axiosTurnosAPI.get("/restrictions");

        if (response.status === 200) {
          setRestricciones(
            response.data.map((e) => {
              return {
                ...e,
                value: e.id,
                label: e.unidad.name,
              };
            })
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, [horaries]);

  useEffect(() => {
    setShowHoraries(
      avariableHorary.filter((e) => {
        let some = horaries.some((b) => e.value === b.value);
        if (some) {
          return false;
        } else {
          return true;
        }
      })
    );
  }, [horaries]);

  const handleCalendar = (days) => {
    if (days?.length) {
      return setDates(days.map((e) => format(new Date(e), "yyyy-MM-dd")));
    } else {
      return setDates([]);
    }
  };

  const handleChange = (e) => {
    setHoraries((prev) => {
      let values = prev.filter((b) => b.uid);
      return [...values, e];
    });
  };

  const handleActivity = (e) => {
    setValues(e);
  };

  const handleSelect = (e, uid) => {
    setHoraries((prev) => {
      let values = prev.filter((b) => b.uid !== uid);
      return [...values, { ...e, uid }];
    });
  };

  const handleSave = async () => {
    if (!dates.length)
      return Swal.fire(
        "Incompleto",
        "Debe seleccionar las fechas del turno",
        "warning"
      );
    if (!values.id)
      return Swal.fire(
        "Incompleto",
        "Debe seleccionar una actividad",
        "warning"
      );
    try {
      let d = dates?.reduce((a, b) => {
        if (!a) {
          return `<span key={${a}}>${formatLocalDate(
            a
          )}</span><span key={${b}}>${formatLocalDate(b)}</span>`;
        }
        return a + `<span key={${b}}>${formatLocalDate(b)}</span>`;
      }, "");
      let h = horaries?.reduce((a, b) => {
        if (typeof a !== "string") {
          return `<span key={${a?.value}}>${a?.value}</span><span key={${b?.value}}>${b?.value}</span>`;
        }
        return a + `<span key={${b?.value}}>${b?.value}</span>`;
      }, "");

      Swal.fire({
        title: "Crear turnos!",
        html: `<div style="font-size: 1rem;">
        <span style="font-size: 18; font-weight: 600;">Fechas</span>
        <div style="display:flex; flex-direction: column;" >${d}</div>
        </br>
        <span style="font-size: 18; font-weight: 600;">Horarios</span>
        <div style="display:flex; flex-direction: column;" >${h}</div>
        </br>
        <p style="font-size: 20px;">¿Desea crear estos turnos?
        </p>
        </div>`,
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
        denyButtonText: "Cancelar",
        denyButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setLoading(true);
          let response = await axiosTurnosAPI.post("/turns/new-turn", {
            dates,
            horaries,
            restriccion: values.id,
            id_usuario: user?.id,
            actividad: values.label,
          });

          if (response?.status === 200) {
            setLoading(false);
            setDates([]);
            return Swal.fire({
              title: "Exito!",
              text: "Los turnos se crearón exitosamente",
              icon: "success",
              didClose: () => navigate("/admin"),
            });
          } else {
            setLoading(false);
            return Swal.fire(
              "Error",
              "Ocurrio un error durante el proceso, intentelo nuevamente",
              "error"
            );
          }
        }
      });
    } catch (err) {
      setLoading(false);
      return Swal.fire(
        "Error",
        "Ocurrio un error durante el proceso, intentelo nuevamente",
        "error"
      );
    }
  };

  const handleAdd = () => {
    let uid = uuidv4();

    setSelects((prev) => {
      if (prev.length) {
        return [
          ...prev,
          {
            uid,
            Select: ReactSelect,
          },
        ];
      } else {
        return [
          {
            uid,
            Select: ReactSelect,
          },
        ];
      }
    });
  };

  const handleRemove = (uid, value) => {
    setSelects((prev) => {
      if (uid) {
        if (prev.length) {
          return prev.filter((e) => e.uid !== uid);
        } else {
          return [];
        }
      } else {
        return prev;
      }
    });

    setHoraries((prev) => prev.filter((e) => e.uid !== uid));
  };

  return (
    <div className="nuevos-turnos">
      <div className="mt-5 mb-5 text-center">
        <h3>Habilitar turnos</h3>
      </div>

      <div className="mt-4">
        <Form.Label className="label">Fechas</Form.Label>
        <Calendar
          onChange={handleCalendar}
          months={months}
          weekDays={weekDays}
          value={dates}
          multiple
        />
      </div>
      <Row className="mt-4">
        <Form.Group>
          <Form.Label className="label">Horario</Form.Label>
          <ReactSelect
            placeholder="Seleccionar..."
            options={avariableHorary}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Row className="t-4">
        {selects?.length
          ? selects.map(({ value, uid, Select }, i) => {
              return (
                <div className="d-flex mt-4" key={uid}>
                  <div style={{ width: "100%" }}>
                    <Select
                      placeholder="Seleccionar..."
                      options={showHoraries}
                      onChange={(e) => handleSelect(e, uid)}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center ms-3 me-2">
                    <i
                      className="fa fa-close"
                      onClick={() => handleRemove(uid, value)}
                      style={{ color: "red", cursor: "pointer" }}
                    ></i>
                  </div>
                </div>
              );
            })
          : ""}
      </Row>
      <Button variant="outline-primary" onClick={handleAdd} className="mt-4">
        + Agregar horario
      </Button>

      <Row className="mt-4">
        <Form.Group>
          <Form.Label className="label">Actividad</Form.Label>
          <ReactSelect
            placeholder="Seleccionar..."
            options={restricciones}
            onChange={handleActivity}
          />
        </Form.Group>
      </Row>

      <Alert variant={"info"} className="mt-5">
        Los nuevos turnos se crearán deshabilitados, para habilitarlos vaya a la
        sección Gestión de Turnos.
      </Alert>
      <div className="mt-4 text-center">
        <Button variant="outline-primary" onClick={handleSave}>
          Guardar
        </Button>
      </div>
      {loading ? <LoadingModal show={loading} /> : ""}
    </div>
  );
};

export default NewTurns;
