export const UnvariableDates = [
  "2022/8/1",
  "2022/8/2",
  "2022/8/3",
  "2022/8/4",
  "2022/8/8",
  "2022/8/9",
  "2022/8/10",
  "2022/8/11",
  "2022/8/16",
  "2022/8/17",
  "2022/8/18",
  "2022/8/22",
  "2022/8/23",
  "2022/8/24",
  "2022/8/25",
];

export const avariableHorary = [
  {
    value: "",
    label: "Seleccionar",
  },
  {
    id: 1,
    value: "08:30",
    label: "08:30",
  },
  {
    id: 2,
    value: "09:00",
    label: "09:00",
  },
  {
    id: 3,
    value: "10:00",
    label: "10:00",
  },
  {
    id: 4,
    value: "10:30",
    label: "10:30",
  },
  {
    id: 5,
    value: "13:30",
    label: "13:30",
  },
  {
    id: 6,
    value: "14:00",
    label: "14:00",
  },
  {
    id: 7,
    value: "15:00",
    label: "15:00",
  },
  {
    id: 8,
    value: "15:30",
    label: "15:30",
  },
];

export const actividades = [
  {
    label: "ECOPARQUE",
    value: "ECOPARQUE",
  },
  {
    label: "TEATRO",
    value: "TEATRO",
  },
  {
    label: "CIRCUITO DE ARTE URBANO",
    value: "CIRCUITO DE ARTE URBANO",
  },
  {
    label: "ESTADIO",
    value: "ESTADIO",
  },
  {
    label: "BUS",
    value: "BUS",
  },
  {
    label: "BARCO",
    value: "BARCO",
  },
];

export const grades = [
  {
    label: "Sala de 5",
    value: "sala_5",
    activity: "ECOPARQUE",
  },
  {
    label: "1° Año (Nivel primario)",
    value: "1_primario",
    activity: "ECOPARQUE",
  },
  {
    label: "2° Año (Nivel primario)",
    value: "2_primario",
    activity: "TEATRO",
  },
  {
    label: "3° Año (Nivel primario)",
    value: "3_primario",
    activity: "CIRCUITO DE ARTE URBANO",
  },
  {
    label: "4° Año (Nivel primario)",
    value: "4_primario",
    activity: "ESTADIO",
  },
  {
    label: "5° Año (Nivel primario)",
    value: "5_primario",
    activity: "BUS",
  },
  {
    label: "6° Año (Nivel primario)",
    value: "6_primario",
    activity: "BARCO",
  },
  {
    label: "1° Año (Nivel secundario)",
    value: "1_secundario",
    activity: "CIRCUITO DE ARTE URBANO",
  },
  {
    label: "2° Año (Nivel secundario)",
    value: "2_secundario",
    activity: "BUS",
  },
  {
    label: "3° Año (Nivel secundario)",
    value: "3_secundario",
    activity: "ECOPARQUE",
  },
  {
    label: "4° Año (Nivel secundario)",
    value: "4_secundario",
    activity: "ESTADIO",
  },
  {
    label: "5° Año (Nivel secundario)",
    value: "5_secundario",
    activity: "BARCO",
  },
  {
    label: "6° Año (Nivel secundario)",
    value: "6_secundario",
    activity: "TEATRO",
  },
];

export const emptyOption = { value: "", label: "Seleccionar" };

export const cargos = [
  {
    value: "Docente",
    label: "Docente",
  },
  {
    value: "Directivo",
    label: "Directivo",
  },
];

export const divisiones = [
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "E",
    label: "E",
  },
  {
    value: "F",
    label: "F",
  },
  {
    value: "G",
    label: "G",
  },
  {
    value: "H",
    label: "H",
  },
  {
    value: "I",
    label: "I",
  },
];

export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export const weekDays = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"];
