import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { comparateDates, sortArray } from "../../utils/utils";
import axios, { axiosTurnosAPI } from "../../api/axios";
import Calendar from "react-calendar";
import Select from "../select/Select";
import { Controller } from "react-hook-form";
import { divisiones, emptyOption } from "../../constants/form";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import "../../assets/styles/Forms.css";

const TurnForm = ({
  register,
  control,
  errors,
  setValue,
  //check,
  watchQtyStudents,
}) => {
  const { escuela } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const [activities, setActivities] = useState([]);
  const [grades, setGrades] = useState([]);

  const [dates, setDates] = useState("");
  const [horaries, setHoraries] = useState("");

  const [activity, setActivity] = useState("");
  const [grade, setGrade] = useState("");

  const [date, setDate] = useState("");
  const [teatroMessage, setTeatroMessage] = useState(null);
  const [turn, setTurn] = useState("");

  const [studentLimit, setStudentLimit] = useState();
  const [copyActivities, setCopyActivities] = useState([]);

  useEffect(() => {
    dispatch(setLoading(true));
    const getDates = async () => {
      let response = await axios.post("grades/available");

      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.actividad,
          value: e.actividad,
        };
      });
      setActivities(result);
      setCopyActivities(result);

      dispatch(setLoading(false));
    };

    getDates();
    setActivity("");
    setGrade("");
    setValue("actividades", "");
    setValue("grade", "");
    setTurn("");
    setValue("horary", "");
    setDate("");
    setValue("date", "");
    setValue("estudiantes", "");
  }, [dispatch, escuela, setValue]);

  useEffect(() => {
    if (activity?.actividad) {
      const getDates = async () => {
        let response = await axiosTurnosAPI.get(
          `/turns/available-dates?actividad=${activity?.actividad}`
        );
        setDates(response.data);
        setTurn("");
      };

      getDates();
    }
  }, [activity]);

  useEffect(() => {
    if (activity?.actividad) {
      if (activity && activity.actividad === "OBRA DE TEATRO") {
        setTeatroMessage(
          `Por favor comuniquese con el teatro para solicitar y coordinar esta visita.
          Correo de contacto: info@teatrosannicolas.com.ar`
        );
      } else {
        setTeatroMessage(null);
        const getHorary = async () => {
          dispatch(setLoading(true));
          let response = await axiosTurnosAPI.get(
            `/turns/horary?fecha=${date ? date : ""}&&actividad=${
              activity?.actividad
            }`
          );
          if (response.status === 200) {
            if (response.data?.length) {
              let result = sortArray(response.data);

              setHoraries(
                result.map((e) => {
                  return {
                    ...e,
                    value: e.horario,
                    label: e.horario,
                  };
                })
              );

              dispatch(setLoading(false));
            } else {
              dispatch(setLoading(false));
              setHoraries([]);
            }
          } else {
            dispatch(setLoading(false));
            setHoraries([]);
          }
        };

        getHorary();
      }
    }
    return () => {
      setHoraries([]);
    };
  }, [date, grade, activity, dispatch]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get("grades");
      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.grado,
          value: e.grado,
        };
      });

      setGrades(result);
    };
    getData();

    return () => {
      setGrades([]);
    };
  }, []);

  const handleSelect = async (event) => {
    setActivity(event);
    setValue("actividades", event?.value);
    switch (event.value) {
      case "BUS":
        setStudentLimit(23);
        break;
      case "OBRA DE TEATRO":
        setStudentLimit(280);
        break;
      default:
        setStudentLimit(35);
        break;
    }

    dispatch(setLoading(false));
  };

  const activitiesArrays = useMemo(() => {
    return {
      withObra: activities.filter(
        (el) =>
          el.actividad === "OBRA DE TEATRO" || el.actividad === "ECOPARQUE"
      ),
      withoutObra: activities.filter((el) => el.actividad !== "OBRA DE TEATRO"),
    };
  }, [activities]);

  const handleGrade = async (e) => {
    setGrade(e);
    setActivity(null);
    setTeatroMessage(null);
    //SETEAR VALUE PARA SACAR ERROR
    setValue("grade", e?.value);

    if (e.value === "Salita de 5 (Nivel inicial)") {
      setCopyActivities(activitiesArrays.withObra);
    } else {
      setCopyActivities(activitiesArrays.withoutObra);
    }
    if (e?.grado_escuela) {
      setValue("division", "");
      if (+e.grado_escuela?.disponibles > 0) {
        setValue("disponibles", +e.grado_escuela?.disponibles - 1);
      } else {
        setValue("disponibles", +e.grado_escuela?.disponibles);
      }
    } else {
      setValue("disponibles", "");
    }
  };

  const handleHorary = (e) => {
    setValue("id_turn", e.id);
    setValue("horary", e.id);
    setTurn(e);
  };

  return (
    <div className="step-form">
      <div>
        <h6 className="text-center">Turno</h6>
      </div>
      <div className="forms">
        <Form.Group>
          <Row className="mb-3">
            {grades?.length ? (
              <Col sm={12} md={6} lg={6}>
                <Form.Label>Curso</Form.Label>
                <Select
                  control={control}
                  options={grades?.length ? grades : [emptyOption]}
                  handleChange={handleGrade}
                  name="grade"
                  value={grade ? grade : ""}
                  controlled={true}
                  className={`${errors.grade ? "is-invalid" : ""}`}
                  disabled={true}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.grade?.message}
                </Form.Control.Feedback>
              </Col>
            ) : (
              ""
            )}
            {grade && copyActivities?.length ? (
              <Col sm={12} md={6} lg={6}>
                <Form.Label>Actividades</Form.Label>
                <Select
                  name="actividades"
                  control={control}
                  options={
                    copyActivities?.length ? copyActivities : [emptyOption]
                  }
                  handleChange={handleSelect}
                  value={activity ? activity : ""}
                  controlled={true}
                  className={`${errors.actividades ? "is-invalid" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.actividades?.message}
                </Form.Control.Feedback>
              </Col>
            ) : (
              ""
            )}
          </Row>

          {/* activity.id 7 'OBRA DE TEATRO' */}
          {grade?.value && activity?.id !== 7 ? (
            <Row className="my-5">
              <Col sm={12} md={4} lg={4}>
                <Form.Label>División del curso</Form.Label>
                <Select
                  control={control}
                  name="division"
                  options={divisiones}
                  className={`${errors.division ? "is-invalid" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.division?.message}
                </Form.Control.Feedback>
              </Col>

              <Col sm={12} md={4} lg={4}>
                <Form.Label>Cantidad de estudiantes para el turno</Form.Label>

                <Form.Control
                  name="estudiantes"
                  type="number"
                  className={`${errors.estudiantes ? "is-invalid" : ""}`}
                  {...register("estudiantes")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.estudiantes?.message}
                </Form.Control.Feedback>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {parseInt(watchQtyStudents) > studentLimit ? (
            "Si la cantidad de alumnos excede el límite permitido, comuniquese al siguiente email para recibir asesoramiento: snmodoexcursion@sannicolas.gob.ar"
          ) : grade?.value && teatroMessage ? (
            <Col sm={12} md={6} className="text-lg ps-3 mt-8 text-blue-600">
              <span>{teatroMessage}</span>
            </Col>
          ) : grade?.value ? (
            <Row className="my-5">
              <h6 className="form-subtitle">Turno</h6>
              <Col sm={12} md={6} className="horary">
                <Form.Label>Seleccionar día</Form.Label>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Calendar
                        onChange={(value) => {
                          setTurn("");
                          setValue("horary", "");

                          setDate(format(new Date(value), "yyyy-MM-dd"));
                          return value
                            ? onChange(format(new Date(value), "yyyy-MM-dd"))
                            : onChange(value);
                        }}
                        minDate={new Date(moment().add(1, "days"))}
                        tileDisabled={({ date }) =>
                          date ? comparateDates(date, dates) : false
                        }
                        className={`${errors.date ? "is-invalid" : ""}`}
                        allowPartialRange
                      />
                    );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date?.message}
                </Form.Control.Feedback>
              </Col>

              {teatroMessage ? (
                <Col sm={12} md={6} className="ps-3 mt-8 text-blue-600">
                  <span>{teatroMessage}</span>
                </Col>
              ) : (
                date && (
                  <Col sm={12} md={6} className="ps-3">
                    <Form.Label>Seleccionar horario</Form.Label>
                    <Select
                      control={control}
                      options={horaries?.length ? horaries : [emptyOption]}
                      handleChange={handleHorary}
                      name="horary"
                      value={turn ? turn : ""}
                      controlled={true}
                      className={`${errors.horary ? "is-invalid" : ""}`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.horary?.message}
                    </Form.Control.Feedback>
                  </Col>
                )
              )}
            </Row>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
    </div>
  );
};

export default TurnForm;
