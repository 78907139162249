import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';

import './assets/styles/App.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-clock/dist/Clock.css';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.less'; // or 'antd/dist/antd.less'

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
