import React from 'react';
import { Modal } from 'react-bootstrap';

const FileModal = ({ show, handleClose, files }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className='date-modal'
    >
      <Modal.Header>
        <div className='d-flex flex-column justify-content-center w-100'>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
          <div className='text-center'>
            <h5>
              Permiso de salida educativa
            </h5>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "80vh", overflow: "auto" }}>
          {files.length ? (
            files.map((e) => {
              if (e.extension === "pdf") {
                return (
                  <div style={{ height: "100%" }} key={"file" + e.id}>
                    <iframe
                      src={`https://staticcontent.sannicolasciudad.gob.ar/images/proyectoescolar/${e.nombre}`}
                      style={{
                        width: '100%',
                        minHeight: 500,
                        height: '100%',
                        objectFit: 'contain',
                        display: 'block',
                      }}
                      title={e.nombre}
                    ></iframe>
                  </div>
                )
              }
              else {
                return (
                  <div style={{ height: "100%" }} key={"file" + e.id}>
                    <a
                      href={`https://staticcontent.sannicolasciudad.gob.ar/images/proyectoescolar/${e.nombre}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-75"
                        src={`https://staticcontent.sannicolasciudad.gob.ar/images/proyectoescolar/${e.nombre}`}
                        alt="permiso"
                      />
                    </a>
                  </div>
                )
              }
            })
          ) : ""}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FileModal;
