import axios from "axios";

// const baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080/";
const baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://proyecto-educativo-api.herokuapp.com";

export default axios.create({
  baseURL,
});


export const axiosPrivate = axios.create({
  baseURL,
  headers: {
      'Content-Type': 'application/json',
  },
});

export const axiosTurnosAPI = axios.create({
  baseURL: process.env.REACT_TURNOS_API_URL ? process.env.REACT_TURNOS_API_URL : "https://turnos-eventos-api.herokuapp.com",
  headers: {
      'Content-Type': 'application/json',
  },
});
