import { Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { logout } from "../../services/auth";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import { emptyOption } from "../../constants/form";
import { getActivities } from "../../services/activities";
import axios from "../../api/axios";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";

const ActivitiesTable = () => {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const user = useUser();

  const [activities, setActivities] = useState([]);
  const [grades, setGrades] = useState([]);
  const [activity, setActivity] = useState("");
  const [grade, setGrade] = useState("");
  const [enabled, setEnabled] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get("activities");
      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.actividad,
          value: e.actividad,
        };
      });

      setActivities(result);
      setData(result);
      setShowData(result);
    };
    getData();

    return () => {
      setData([]);
      setShowData([]);
      setActivities([]);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get("grades");
      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.grado,
          value: e.grado,
        };
      });

      setGrades(result);
    };
    getData();

    return () => {
      setGrades([]);
    };
  }, []);

  useEffect(() => {
    let result = data.filter((e) => {
      let actividad = e?.actividad?.includes(activity);
      let grado = e?.Grados?.some((e) =>
        e?.grado ? e.grado.includes(grade) : "".includes(grade)
      );
      let disponible = e?.enabled?.toString().includes(enabled);

      return actividad && grado && disponible;
    });

    setShowData(result);
  }, [activity, grade, data, enabled]);

  const handleRefresh = async (e) => {
    const data = await getActivities();

    setActivity("");
    setGrade("");
    setEnabled("");

    setData(data);
    setShowData(data);
  };

  const changeState = async (data, state) => {
    if (data) {
      return Swal.fire({
        title: `<h3>${state} actividad</h3>`,
        html: `<div>
          <p>¿Esta seguro de ${state} esta actividad?</p>
          <p><span style="font-weight:600;">Actividad:</span> ${
            data?.actividad ? data.actividad : ""
          }</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            let response = await axiosPrivate.put("/activities/state", {
              id: data?.id,
              id_usuario: user?.id,
            });

            if (response.status === 200) {
              await handleRefresh();

              return Swal.fire({
                title: "¡Éxito!",
                text: `¡La actividad se actualizón exitosamente!`,
                icon: "success",
              });
            } else {
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const formatData = (array) => {
    try {
      if (array?.length) {
        return array.map((e) => {
          let { enabled } = e;

          return {
            ...e,
            disponible: enabled ? "Si" : "No",
            uuid: uuidv4(),
            action: (function a() {
              return (
                <div className="d-flex justify-content-center">
                  {/* <Button className='me-3' onClick={() => handleShow(e)}><i className='fa fa-edit me-2'></i> Editar</Button> */}
                  {enabled ? (
                    <Button
                      className="me-3"
                      variant="outline-danger"
                      onClick={() => changeState(e, "Deshabilitar")}
                    >
                      <i className="fa fa-times me-2"></i> Deshabilitar
                    </Button>
                  ) : (
                    <Button
                      className="me-3"
                      variant="outline-success"
                      onClick={() => changeState(e, "Habilitar")}
                    >
                      <i className="fa fa-check me-2"></i> Habilitar
                    </Button>
                  )}
                </div>
              );
            })(),
          };
        });
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleActivities = (e) => {
    if (e) {
      setActivity(e.value);
    } else {
      setActivity("");
    }
  };

  const handleGrades = (e) => {
    if (e) {
      setGrade(e.value);
    } else {
      setGrade("");
    }
  };

  const handleChange = (e) => {
    setEnabled(e.target.value);
  };

  return (
    <>
      <div>
        <Row className="mt-4 mb-3">
          <Col>
            <FormLabel>Actividad</FormLabel>
            <ReactSelect
              options={
                activities?.length
                  ? [emptyOption, ...activities]
                  : [emptyOption]
              }
              name="actividades"
              onChange={handleActivities}
              value={activity ? { label: activity, value: activity } : ""}
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
          <Col>
            <FormLabel>Grado</FormLabel>
            <ReactSelect
              options={
                grades?.length ? [emptyOption, ...grades] : [emptyOption]
              }
              name="grados"
              onChange={handleGrades}
              value={grade ? { label: grade, value: grade } : ""}
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
          <Col>
            <FormLabel>Disponible</FormLabel>
            <FormControl
              as={"select"}
              name="disponible"
              onChange={handleChange}
            >
              <option value={""}>Seleccionar</option>
              <option value={"1"}>Si</option>
              <option value={"0"}>No</option>
            </FormControl>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mb-5">
          <Button variant="outline-primary" onClick={handleRefresh}>
            Actualizar
          </Button>
        </div>
      </div>
      <Table
        columns={columns.activities}
        dataSource={formatData(showData)}
        rowKey={(row) => row?.uuid}
      />
    </>
  );
};

export default ActivitiesTable;
