const useUser = () => {
  const user = sessionStorage.getItem("user");

  if (user) {
    let result = JSON.parse(user);
    
    return result;
  }
  else {
    return "";
  }
}

export default useUser;