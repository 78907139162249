import { Outlet } from "react-router-dom"
import Header from "../header"

import "../../assets/styles/Container.css";

const Container = () => {
  return (
    <div className="container-main">
      <Header />
      <Outlet />
    </div>
  )
}

export default Container;