import axios, { axiosTurnosAPI } from "../api/axios";
import { activitiesFormat } from "../utils/turnsFormat";

export const getActivities = async () => {
  try{

    const response = await axios.get("activities");
    
    let result = activitiesFormat(response.data);
    
    return result;
  }
  catch(err){
    return err;
  }
};

export const getWeekActivities = async () => {
  try {
    const response = await axiosTurnosAPI.get("/week/getWeekActivities");

    return response.data;
  }
  catch (err) {
    console.log(err);
    return err;
  }
};

export const getUnidadSemanas = async () => {
  try {
    const response = await axios.get("units/getUnidadSemanas");

    return response.data;
  }
  catch (err) {
    console.log(err);
    return err;
  }
};
