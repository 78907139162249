export const turnsFormat = (array, element) => {
  try {
    if (array && array.length) {
      return array?.map((e) => {
        let c = {
          ...e,
          agent: e.Agentes_Subagente?.Agente,
          subagents: e.Agentes_Subagente?.Subagente,
          gradoId: e.Agentes_Subagente?.Subagente?.id,
          cumplido: e.cumplido,        
          fecha: element.fecha,
          horario: element.horario,
          disponibles: element.disponibles,
          grado: e.Agentes_Subagente?.Subagente?.nombre,
          restriccion: element.restriccion,
          estado: element.Reservas?.length ? element.Reservas[0].estado : ""
        };

        return c;
      }
      )
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const turnsFormatExcel = (array) => {
  try {
    if (array && array.length) {
      let data = array.map(e => {
        let reserves = e.Reservas.map(r => {
          return {
            fecha: e.fecha,
            horario: e.horario,
            cumplido: r.cumplido ? "SI" : "",
            name_adult: `${r.agent.nombre} ${r.agent.apellido}`,
            dni_adult: r.agent.dni,
            children: r.reserves?.length,
            age_children: r.subagents.reduce((a, b) => {
              if (a) {
                return a + ` - Edad: ${b.edad}`
              } else {
                return `Edad: ${b.edad}`
              }
            }, "")
          }
        })

        return reserves;
      });

      let result = data?.flat()?.sort((a, b) => {
        let value_a = a?.horario?.split("-")[0]?.split(":")[0];
        let value_b = b?.horario?.split("-")[0]?.split(":")[0];

        if (+value_a > +value_b && new Date(a.fecha).getTime() >= new Date(b.fecha).getTime()) {
          return 1;
        }
        if (+value_a < +value_b && new Date(a.fecha).getTime() <= new Date(b.fecha).getTime()) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })

      return result;

    } else {
      return []
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};


export const activitiesFormat = (array, element) => {
  try {
    if (array && array.length) {
      return array?.reduce((a, b) => {
        if (a?.length) {
          if (a.some(e => e?.id === b.id)) {
            let c = a.map(e => {
              if (e?.id === b?.id) {
                return {
                  ...e,
                  grados: [...e.grados, b.Grado],
                }
              } else {
                return e;
              }
            });

            return c;
          }
          else {
            let c = [...a, {
              ...b,
              grados: [b.Grado]
            }];

            return c;
          }
        } else {
          let c = [{
            ...b,
            grados: [b.Grado]
          }];

          return c;
        }
      }, [])
    } else {
      return []
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};