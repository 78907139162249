import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import { axiosTurnosAPI } from "../../api/axios";
import DateModal from "../dateModal/DateModal";
import { setLoading } from "../../redux/formDataSlice";
import { useDispatch } from "react-redux";

const Callendar = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [turn, setTurn] = useState("");

  const getDates = async () => {
    try {
      dispatch(setLoading(true));
      axiosTurnosAPI
        .post("/reserves/getReserves")
        .then((response) => {
          let result = response.data.map((e) => {
            return {
              ...e,
              turnId: e?.id,
              title: e?.restriccion?.unidad?.name,
              date: e?.fecha,
              start: `${e?.fecha}T${e?.horario}:00`,
            };
          });

          setEvents(result);
          dispatch(setLoading(false));
          return;
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          return;
        });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getDates();
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const renderEventContent = (content) => {
    return (
      <>
        <div
          className={`fc-daygrid-event-dot estado-${
            content?.event?.extendedProps?.Reservas[0]?.estado?.toLowerCase() ||
            ""
          }`}
        ></div>
        <div
          className={`fc-event-time mx-2 estado-${
            content?.event?.extendedProps?.Reservas[0]?.estado?.toLowerCase() ||
            ""
          }`}
        >
          {content.timeText} hs
        </div>
        <div className="fc-event-title">
          <span className="me-2">-</span>
          <span
            className={`fw-bold estado-${
              content?.event?.extendedProps?.Reservas[0]?.estado?.toLowerCase() ||
              "text-black"
            }`}
          >
            {content.event.title}
          </span>
        </div>
      </>
    );
  };

  const handleEventClick = (eventInfo) => {
    handleShow();
    setTurn(eventInfo?.event?.extendedProps);
  };

  return (
    <div style={{ minWidth: 400 }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        buttonText={{
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Dia",
          list: "Lista",
        }}
        locales={[esLocale]}
      />
      {show ? (
        <DateModal
          show={show}
          handleClose={handleClose}
          data={turn}
          setEvents={setEvents}
          getData={getDates}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Callendar;
