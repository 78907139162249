import * as yup from "yup";

export const validation = yup.object().shape({
  name: yup.string().required("Este campo es obligatorio"),
  lastname: yup.string().required("Este campo es obligatorio"),
  dni: yup
    .number()
    .typeError("Ingrese un N° de DNI")
    .min(100000, "Debe ser un DNI válido")
    .max(99999999, "El DNI es demasiado largo")
    .required("Este campo es obligatorio"),
  email: yup
    .string()
    .email("Debe ser un domicilio electrónico válido")
    .required("Este campo es obligatorio"),
  mobilephone: yup
    .number()
    .typeError("Ingrese un número de telefono")
    .min(100000, "Debe ser un número de telefono válido")
    .max(999999999999, "El número de telefono es demasiado largo")
    .required("Este campo es obligatorio"),
  name_school: yup.string().required("Este campo es obligatorio"),

  cargo: yup.string().required("Este campo es obligatorio"),

  actividades: yup.string().required("Este campo es obligatorio"),
  grade: yup.string().required("Este campo es obligatorio"),
  division: yup.string().required("Este campo es obligatorio"),

  estudiantes: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable()
    .when("actividades", {
      is: (value) => value && value === "BUS",
      then: yup
        .number()
        .typeError("Este campo es requerido")
        .required()
        .min(1, "El número no puede ser menor a 1")
        .max(23, "El bus tiene un limite de 23 personas"),
    })
    .when("actividades", {
      is: (value) => value && value === "BARCO",
      then: yup
        .number()
        .typeError("Este campo es requerido")
        .required()
        .min(1, "El número no puede ser menor a 1")
        .max(44, "El barco tiene un limite de 44 personas"),
    })
    .when("actividades", {
      is: (value) => value && value === "OBRA DE TEATRO",
      then: yup
        .number()
        .typeError("Este campo es requerido")
        .required()
        .min(1, "El número no puede ser menor a 1")
        .max(280, "La obra de teatro tiene un limite de 280 personas"),
    })
    .when("actividades", {
      is: (value) =>
        value &&
        (value === "ESTADIO" ||
          value === "CIRCUITO DE ARTE URBANO" ||
          value === "ECOPARQUE" ||
          value === "TEATRO"),
      then: yup
        .number()
        .typeError("Este campo es requerido")
        .required()
        .min(1, "El número no puede ser menor a 1")
        .max(35, `Esta actividad tiene un limite de 35 personas`),
    }),

  estudiantes_2: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable()
    .when("add_turn", (value, schema) => {
      return value && value === "SI"
        ? schema
            .when("actividades", {
              is: (value) => value && value === "BUS",
              then: yup
                .number()
                .typeError("Este campo es requerido")
                .required()
                .min(1, "El número no puede ser menor a 1")
                .max(23, "El bus tiene un limite de 23 personas"),
            })
            .when("actividades", {
              is: (value) => value && value === "BARCO",
              then: yup
                .number()
                .typeError("Este campo es requerido")
                .required()
                .min(1, "El número no puede ser menor a 1")
                .max(44, "El barco tiene un limite de 44 personas"),
            })
            .when("actividades", {
              is: (value) =>
                value &&
                (value === "ESTADIO" ||
                  value === "CIRCUITO DE ARTE URBANO" ||
                  value === "ECOPARQUE"),
              then: yup
                .number()
                .typeError("Este campo es requerido")
                .required()
                .min(1, "EL número no puede ser menor a 1")
                .max(35, `Esta actividad tiene un limite de 35 personas`),
            })
        : schema;
    }),
  date: yup.string().required("Este campo es obligatorio"),
  horary: yup.string().required("Este campo es obligatorio"),

  date_2: yup.string().when("add_turn", {
    is: (value) => value === "SI",
    then: yup.string().required("Este campo es obligatorio"),
  }),

  horary_2: yup.string().when("add_turn", {
    is: (value) => value === "SI",
    then: yup.string().required("Este campo es obligatorio"),
  }),
});
