import axios from "../api/axios";

export const login = async ({ username, password }) => {
  let response = await axios.post("/auth/login", { username, password });

  if (response?.data?.id) {
    sessionStorage.setItem("user", JSON.stringify(response?.data));
  }

  return response;
}

export const logout = async () => {
  sessionStorage.removeItem("user");
}