export const columns = {
  activities: [
    {
      title: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
    },
    {
      title: 'Grados',
      key: 'grados',
      render: ({ actividad, Grados }) => {
        return (
          Grados?.length ? <ul>
            {Grados.map((e, i) => <li key={e?.grado + actividad + i}>{e?.grado}</li>)}
          </ul> : ""
        )
      }
    },
    {
      title: 'Disponible',
      dataIndex: 'disponible',
      key: 'disponible',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      align: "center"
    },
  ]
}