export const columns = {
  activities: [
    {
      title: 'Grado',
      dataIndex: 'grado',
      key: 'grado',
    },
    {
      title: 'Actividad',
      key: 'actividad',
      render: ({ actividades }) => {
        return (
          actividades ? actividades.actividad : ""
        )
      }
    },
    {
      title: 'Disponible',
      dataIndex: 'disponible',
      key: 'disponible',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      align: "center"
    },
  ]
}