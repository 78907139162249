import { createSlice } from "@reduxjs/toolkit";
import { getDates, getGrades, submit } from "./actionsAsync";


const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    result: "",
    status: "",
    escuela: "",
    loading: false,
  },
  reducers: {
    setSchool: (state, data) => {
      state.escuela = data.payload;
    },
    setLoading: (state, data) => {
      state.loading = data.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(submit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submit.fulfilled, (state, action) => {
        state.status = 'success';
        state.result = action.payload;
      })
      .addCase(submit.rejected, (state, action) => {
        state.status = 'failure';
        state.result = "";
        state.error = action.payload;
      })
      //------------------------------------------------------------//
      .addCase(getGrades.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        state.status = 'success';
        state.grades = action.payload;
      })
      .addCase(getGrades.rejected, (state, action) => {
        state.status = 'failure';
        state.grades = [];
        state.error = action.payload;
      })
      //------------------------------------------------------------//
      .addCase(getDates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDates.fulfilled, (state, action) => {
        state.status = 'success';
        state.dates = action.payload;
      })
      .addCase(getDates.rejected, (state, action) => {
        state.status = 'failure';
        state.dates = [];
        state.error = action.payload;
      })
  }
})

export const { setSchool, setLoading } = formDataSlice.actions;

export default formDataSlice;