import Container from "../components/container";
import FormPage from "../pages/form";
import { Routes, Route } from "react-router-dom";
import Admin from "../pages/admin/Admin";
import PrivateRouter from "./privateRouter/PrivateRouter";
import Confirmacion from "../pages/confirmacion/Confirmacion";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Container />}>
        <Route path="/" element={<FormPage />} />
        <Route path="/confirmar/:token" element={<Confirmacion />} />
        <Route path="/admin" element={
          <PrivateRouter>
            <Admin />
          </PrivateRouter>
        } />
      </Route>
    </Routes>
  )
};

export default AppRoutes;
