import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";
import useUser from "./useUser";


const useAxiosPrivate = () => {
    const navigate = useNavigate();
    const user = useUser();
    const token = user ? user.token : undefined;

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
        }
    }, [token , navigate])

    return axiosPrivate;
}

export default useAxiosPrivate;