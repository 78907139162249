import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { axiosTurnosAPI } from "../../api/axios";
import { turnsFormat } from "../../utils/turnsFormat";
import { avariableHorary, emptyOption } from "../../constants/form";
import { setLoading } from "../../redux/formDataSlice";

const ReservesFilter = ({ setData, submitRef }) => {
  const { grades } = useSelector((state) => state.data);
  const { handleSubmit, register, control } = useForm();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  console.log("🚀 ~ ReservesFilter ~ dates:", dates)

  const [values, setValues] = useState({
    escuela: "",
    grado: "",
    fecha: "",
    horario: "",
  });

  useEffect(() => {
    const getDates = async () => {
      let response = await axiosTurnosAPI.get("/turns/dates");
      if (response?.data?.length) {
        setDates(response.data);
      }
    };

    getDates();
    return () => {};
  }, []);

  const submit = async (values) => {
    try {
      setValues(values);
      dispatch(setLoading(true));
      let response = await axiosTurnosAPI.post("/reserves/getReserves", values);

      let result = response.data
        ?.map((element) => {
          return turnsFormat(element?.Reservas, element);
        })
        .flat();

      setData(result);
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      setData([]);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="bg-white rounded">
      <Card.Body>
        <h4>Filtros</h4>
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Group>
            <Row>
              <Col sm={12} md={6} lg={6} className="mt-3">
                <Form.Label className="label">Escuela</Form.Label>
                <Form.Control name="escuela" {...register("escuela")} />
              </Col>
              <Col sm={12} md={6} lg={6} className="mt-3">
                <Form.Label className="label">Grado</Form.Label>
                <Select
                  control={control}
                  name="grado"
                  options={
                    grades?.length ? [emptyOption, ...grades] : [emptyOption]
                  }
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={3} lg={3} className="mt-3">
                <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
                  Fecha desde:
                </Form.Label>
                <Form.Control
                  name="fecha_desde"
                  type="date"
                  {...register("fecha_desde")}
                  required
                />
              </Col>
              <Col sm={6} md={3} lg={3} className="mt-3">
                <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
                  Fecha hasta:
                </Form.Label>
                <Form.Control
                  name="fecha_hasta"
                  type="date"
                  {...register("fecha_hasta")}
                  required
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="mt-3">
                <Form.Label className="label">Horario</Form.Label>
                <Select
                  control={control}
                  name="horario"
                  options={avariableHorary}
                  isClearable={true}
                />
              </Col>
            </Row>
          </Form.Group>
          <div className="d-flex justify-content-center mt-5">
            <Button variant="outline-primary" type="submit" ref={submitRef}>
              Buscar
            </Button>
          </div>
        </Form>

        <div className="mt-3">
          {values.escuela ? (
            <Tag color="green" className="admin-tags mb-2">
              Escuela: {values.escuela}
            </Tag>
          ) : (
            ""
          )}
          {values.grado ? (
            <Tag color="green" className="admin-tags mb-2">
              Grado: {values.grado}
            </Tag>
          ) : (
            ""
          )}
          {values.fecha ? (
            <Tag color="green" className="admin-tags mb-2">
              Fecha: {values.fecha}
            </Tag>
          ) : (
            ""
          )}
          {values.horario ? (
            <Tag color="green" className="admin-tags mb-2">
              Horario: {values.horario}
            </Tag>
          ) : (
            ""
          )}
        </div>
      </Card.Body>
    </div>
  );
};

export default ReservesFilter;
