import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatLocalDate } from "../../utils/utils";
import Select from "../select/Select";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { emptyOption } from "../../constants/form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosTurnosAPI } from "../../api/axios";
import { logout } from "../../services/auth";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import { stylesReactSelect } from "../select/styles";
import useUser from "../../hooks/useUser";
import FileModal from "../fileModal/FileModal";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";

import "../../assets/styles/Modals.css";

const DateModal = ({ show, handleClose, data, setEvents, getData }) => {
  const { handleSubmit, control, setValue } = useForm();
  const axiosPrivateTurnosAPI = useAxiosTurnosAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const [dates, setDates] = useState([]);
  const [horaries, setHoraries] = useState([]);
  const [date, setDate] = useState("");
  const [horary, setHorary] = useState("");
  const [turn, setTurn] = useState("");

  const [showFile, setShowFile] = useState(false);
  const [files, setFiles] = useState([]);

  let reserva = data?.Reservas?.length ? data?.Reservas[0] : "";

  useEffect(() => {
    const getDates = async () => {
      let actividad = data?.restriccion?.unidad?.name ?? "";

      let response = await axiosTurnosAPI.get(
        `/turns/available-dates?actividad=${actividad}`
      );

      let result = response.data.map((e) => {
        return {
          label: formatLocalDate(e),
          value: e,
        };
      });

      result = result?.sort(
        (a, b) => new Date(a?.value).getTime() - new Date(b?.value).getTime()
      );
      setDates(result);
    };

    getDates();
    return () => {};
  }, [data]);

  useEffect(() => {
    if (date) {
      const getDates = async () => {
        let actividad = data?.restriccion?.unidad?.name ?? "";

        let response = await axiosTurnosAPI.get(
          `/turns/horary?fecha=${date}&&actividad=${actividad}`
        );

        let result = response.data.map((e) => {
          return {
            ...e,
            label: e.horario,
            value: e.horario,
          };
        });
        setHoraries(result);
      };

      getDates();

      setTurn("");
      setHorary("");
      setValue("horario", "");

      return () => {
        setHoraries([]);
      };
    }
  }, [date, setValue, data]);

  const handleChange = (e) => {
    if (e) {
      setDate(e.value);
    } else {
      setDate("");
    }
  };

  const handleTurn = (e) => {
    setTurn(e);
    setHorary(e);
  };

  const deleteReserves = async () => {
    if (data) {
      return Swal.fire({
        title: `<h3 style="color:#dc3741;">Cancelar turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de cancelar este turno?</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            dispatch(setLoading(true));
            let response = await axiosPrivateTurnosAPI.post(
              "/reserves/cancelReserve",
              {
                id: data.Reservas[0].id,
                idTurn: data.turnId,
                id_usuario: user?.id,
                reserva: {
                  fecha: formatLocalDate(data?.fecha),
                  horario: data.horario,
                  actividad: data?.restriccion?.unidad?.name,
                  escuela:
                    reserva?.Agentes_Subagente?.Agente?.agente_entidad?.entidad
                      ?.nombre,
                  grado: `${reserva?.Agentes_Subagente?.Subagente?.nombre}`,
                  division: `${reserva?.Agentes_Subagente?.Subagente?.division}`,
                  representante: `${reserva?.Agentes_Subagente?.Agente?.nombre} ${reserva?.Agentes_Subagente?.Agente?.apellido}`,
                  email: reserva?.Agentes_Subagente?.Agente?.email,
                },
              }
            );

            if (response.status === 200) {
              getData();
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "Se cancelo exitosamente.",
                icon: "success",
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "¡Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const confirmReserve = async () => {
    if (data) {
      return Swal.fire({
        title: `<h3 style="color:#198754;">Confirmar turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de confirmar este turno?</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            dispatch(setLoading(true));
            let response = await axiosPrivateTurnosAPI.patch(
              "/reserves/changeStateReserve",
              {
                id: data.Reservas[0].id,
                estado: "CONFIRMADO",
              }
            );

            if (response.status === 200) {
              getData();
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "Se cancelo exitosamente.",
                icon: "success",
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "¡Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const submit = (values) => {
    if (!values.fecha || !values.horario || !turn)
      return Swal.fire(
        "Incompleto",
        "Debe elegir una fecha y horario",
        "warning"
      );
    if (data?.reserves?.length > turn.disponibles)
      return Swal.fire(
        "Turnos insuficientes",
        "No hay suficientes turnos en esta fecha",
        "warning"
      );

    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar turnos</h3>`,
      html: `<div>
        <p>¿Esta seguro de estos cambios?</p>
        <p><span style="font-weight:600;">Anterior fecha:</span> ${
          data.fecha ? formatLocalDate(data.fecha) : ""
        } a las ${data.horario ? data.horario : ""}</p>
        <p><span style="font-weight:600;">Nueva fecha:</span> ${formatLocalDate(
          values.fecha
        )} a las ${values.horario}</p>
      </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));

          let response = await axiosTurnosAPI.patch("/reserves/changeTurn", {
            id: data.Reservas[0].id,
            idTurn: data.turnId,
            newTurn: turn.id,
            id_usuario: user?.id,
            prevTurn: `${data.fecha ? formatLocalDate(data.fecha) : ""} a las ${
              data.horario ? data.horario : ""
            }`,
            nextTurn: `${formatLocalDate(values.fecha)} a las ${
              values.horario
            }`,
            reserva: {
              actividad: data?.restriccion?.unidad?.name,
              escuela:
                data?.Reservas[0].Agentes_Subagente.Agente?.agente_entidad
                  ?.entidad?.nombre,
              grado: `${data?.Reservas[0].Agentes_Subagente.Subagente?.nombre}`,
              division: `${data?.Reservas[0].Agentes_Subagente.Subagente?.division}`,
              representante: `${data?.Reservas[0].Agentes_Subagente.Agente?.nombre} ${data?.Reservas[0].Agentes_Subagente.Agente?.apellido}`,
              email: data?.Reservas[0].Agentes_Subagente.Agente?.email,
            },
          });

          if (response.status === 200) {
            getData();

            dispatch(setLoading(false));

            return Swal.fire({
              title: "¡Éxito!",
              text: "El turno fue modificado exitosamente",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            dispatch(setLoading(false));
            return Swal.fire({
              title: "Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  const handleShowFile = (e) => {
    setShowFile(true);
    setFiles(e);
  };

  const handleCloseFile = (e) => {
    setShowFile(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="date-modal">
      <Modal.Header>
        <div className="d-flex flex-column justify-content-center w-100">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div className="text-center">
            <h5>TURNO</h5>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column turn-info">
          <div className="mt-3">
            <span className="info-name">Actividad:</span>
            <span>{data?.restriccion?.unidad?.name}</span>
          </div>
          <div>
            <span className="info-name">Fecha:</span>
            <span>{formatLocalDate(data?.fecha)}</span>
          </div>
          <div>
            <span className="info-name">Horario:</span>
            <span>{data?.horario}</span>
          </div>
          <div>
            <span className="info-name">Estado:</span>
            <span>{reserva?.estado}</span>
          </div>
          <div>
            <span className="info-name">Escuela:</span>
            <span>
              {
                reserva?.Agentes_Subagente?.Agente?.agente_entidad?.entidad
                  ?.nombre
              }
            </span>
          </div>
          <div>
            <span className="info-name">Grado:</span>
            <span>{reserva?.Agentes_Subagente?.Subagente?.nombre}</span>
          </div>
          <div>
            <span className="info-name">Representante:</span>
            <span>{reserva?.Agentes_Subagente?.Agente?.apellido}</span>{" "}
            <span>{reserva?.Agentes_Subagente?.Agente?.nombre}</span>
          </div>
          <div>
            <span className="info-name">Celular:</span>
            <span>{reserva?.Agentes_Subagente?.Agente?.telefono}</span>
          </div>
          <div>
            <span className="info-name">Email:</span>
            <span>{reserva?.Agentes_Subagente?.Agente?.email}</span>
          </div>
          <div>
            <span className="info-name">Personas:</span>
            <span>{reserva?.personas}</span>
          </div>
          {reserva.Archivos_Reservas.length ? (
            <div>
              <Button
                className="mt-3 me-3"
                variant="outline-ghost"
                onClick={() => handleShowFile(reserva.Archivos_Reservas)}
              >
                <i className="fa fa-eye me-2"></i> Ver permisos
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* <div>
          <div className='subtitle'>
            <span>Editar turno</span>
          </div>
          <Form onSubmit={handleSubmit(submit)}>
            <Form.Group>
              <Row>
                <Col className='mt-3'>
                  <Form.Label className="label">Fecha <span className='text-red'>*</span></Form.Label>
                  <Select
                    control={control}
                    name="fecha"
                    options={
                      dates?.length ? [emptyOption, ...dates] : [emptyOption]
                    }
                    isClearable={true}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              {date ? (
                <Row>
                  <Col className='mt-3'>
                    <Form.Label className="label">Horario <span className='text-red'>*</span></Form.Label>
                    <Controller
                      control={control}
                      name="horario"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <ReactSelect
                            options={
                              horaries?.length ? [emptyOption, ...horaries] : [emptyOption]
                            }
                            onChange={(e) => {
                              handleTurn(e)
                              return onChange(e?.value)
                            }}
                            isClearable
                            value={horary}
                            styles={stylesReactSelect}
                          />
                        )
                      }}
                    />

                  </Col>
                </Row>
              ) : ""}
            </Form.Group>
          </Form>
        </div> */}
        <div className="d-flex justify-content-center mt-5">
          {/* <Button type="submit" className='me-3'>Cambiar</Button> */}
          {reserva?.estado !== "CANCELADO" ? (
            <Button
              variant="outline-danger"
              className="me-3"
              onClick={() => deleteReserves()}
            >
              <i className="fa fa-remove me-2"></i> Cancelar
            </Button>
          ) : (
            ""
          )}
          {reserva?.estado !== "CONFIRMADO" ? (
            <Button
              className="me-3"
              variant="outline-success"
              onClick={() => confirmReserve()}
            >
              <i className="fa fa-check me-2"></i> Confirmar
            </Button>
          ) : (
            ""
          )}
          <Button variant="outline-dark" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
        {showFile ? (
          <FileModal
            show={showFile}
            handleClose={handleCloseFile}
            files={files}
          />
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DateModal;
