import { Table } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import axios from '../../api/axios';
import { formatLocalDate, formatLocalDBDate } from '../../utils/utils';

const columns = [
  {
    title: 'Usuario',
    dataIndex: 'usuario',
    key: 'usuario',
  },
  {
    title: 'Acción',
    dataIndex: 'descripcion',
    key: 'descripcion',
  },
  {
    title: 'Descripción',
    dataIndex: 'accion',
    key: 'accion',
  },
  {
    title: 'Turno',
    dataIndex: 'turno',
    key: 'turno',
    width: 200
  },
  {
    title: 'Reserva',
    dataIndex: 'reserva',
    key: 'reserva',
    width: 400
  },
  {
    title: 'Actividad',
    dataIndex: 'actividad',
    key: 'actividad',
  },
  {
    title: 'Grado',
    dataIndex: 'grado',
    key: 'grado',
    width: 200
  },
  {
    title: 'Fecha de la acción',
    dataIndex: 'fecha_creacion',
    key: 'fecha_creacion',
    width: 150
  },
]


const RecordsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let response = await axios.get("records");

      setData(response.data);
    };
    getData();

  }, []);


  const formatData = (array) => {
    try {
      if (array?.length) {
        return array.map(e => {
          let { tipo_accion, accion, usuario, turno, reserva, actividad, grado, restriccion, createdAt } = e;

          return {
            ...e,
            descripcion: <div style={{ minWidth: 100 }}>{tipo_accion?.descripcion}</div>,
            accion: <div style={{ minWidth: 300 }}>{accion}</div>,
            usuario: `${usuario?.username ? usuario.username : ""}`,
            turno: `${turno?.fecha ? formatLocalDate(turno.fecha) + " - " : ""} ${turno?.horario ? turno.horario + " hs" : ""}`,
            reserva: `${reserva?.agente_entidad?.entidad?.nombre ? `Escuela : ${reserva?.agente_entidad?.entidad?.nombre} - ` : ""} ${reserva?.agente_subagente?.subagente?.nombre ? `Grado: ${reserva?.agente_subagente?.subagente?.nombre}` : ""} ${reserva?.agente_subagente?.subagente?.division ? `División: ${reserva?.agente_subagente?.subagente?.division}` : ""}`,
            actividad: actividad ? actividad.name : restriccion?.unidad?.name ? restriccion?.unidad?.name : "",
            grado: grado ? grado.name : "",
            fecha_creacion: createdAt ? formatLocalDBDate(createdAt) : ""
          }
        })
      } else {
        return [];
      }
    }
    catch (err) {
      console.log(err);
      return []
    }
  };


  return (
    <div>
      <Table
        columns={columns}
        dataSource={formatData(data)}
        rowKey={() => uuidv4()}
      />

    </div>
  )
};

export default RecordsTable;