import React, { useEffect } from "react";
import { Table } from "antd";
import { columns } from "./columns";
import { useState } from "react";
import axios, { axiosTurnosAPI } from "../../api/axios";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { formatLocalDate } from "../../utils/utils";
import EditTurns from "../editTurns/EditTurns";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";
import { avariableHorary, emptyOption } from "../../constants/form";
import ReactSelect from "react-select";
import ChangeActivity from "../changeActivity";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";

const TurnsTable = () => {
  const navigate = useNavigate();
  const user = useUser();

  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState("");

  const [dates, setDates] = useState("");
  const [activities, setActivities] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [horary, setHorary] = useState("");
  const [enabled, setEnabled] = useState("");
  const [activityFilter, setActivityFilter] = useState("");

  const [show, setShow] = useState(false);
  const [activity, setActivity] = useState("");

  const getDates = async () => {
    let response = await axiosTurnosAPI.get("/turns");

    if (response?.data?.length) {
      setData(response.data);
      setShowData(response.data);

      let fechas = response.data.map((e) => {
        return {
          ...e,
          label: formatLocalDate(e?.fecha),
          value: e?.fecha,
        };
      });

      let result = [];

      fechas.forEach((fecha) => {
        if (!result.some((e) => e.fecha === fecha.fecha)) {
          result.push(fecha);
        }
      });

      setDates(result.sort((a, b) => new Date(a?.fecha) - new Date(b?.fecha)));
    }
  };

  useEffect(() => {
    getDates();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get("activities");
      let result = response.data?.map((e) => {
        return {
          ...e,
          label: e.actividad,
          value: e.actividad,
        };
      });

      setActivities(result);
    };
    getData();

    return () => {
      setActivities([]);
    };
  }, []);

  useEffect(() => {
    let result = data?.filter((e) => {
      let fecha =
        (startDate ? new Date(e.fecha) >= new Date(startDate) : true) &&
        (endDate ? new Date(e.fecha) <= new Date(endDate) : true);
      let horario = e?.horario?.includes(horary);
      let disponible = e?.enabled?.toString()?.includes(enabled);
      let actividad = e?.restriccion?.unidad?.name
        ?.toString()
        ?.includes(activityFilter);

      return fecha && horario && disponible && actividad;
    });

    setShowData(result);
  }, [data, startDate, endDate, horary, enabled, activityFilter]);

  const handleShow = (data) => {
    setEditData(data);
    return setShowEdit(true);
  };
  const handleClose = () => setShowEdit(false);

  const changeState = async (data, state) => {
    if (data) {
      return Swal.fire({
        title: `<h3>${state} turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de ${state} este turno?</p>
          <p><span style="font-weight:600;">Turno:</span> ${
            data.fecha ? formatLocalDate(data.fecha) : ""
          } a las ${data.horario ? data.horario : ""}</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            let response = await axiosTurnosAPI.put("/turns/state", {
              id: data.id,
              id_usuario: user?.id,
            });

            if (response.status === 200) {
              await getDates();

              return Swal.fire({
                title: "¡Éxito!",
                text: "Los turnos se actualizón exitosamente.",
                icon: "success",
              });
            } else {
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const handleActivity = (e) => {
    setShow(true);
    setActivity(e);
  };

  const formatData = (array) => {
    try {
      return array.map((e) => {
        let { fecha, enabled, restriccion } = e;

        return {
          ...e,
          fecha: fecha ? formatLocalDate(fecha) : "",
          disponible: enabled ? "Si" : "No",
          actividad: restriccion?.unidad?.name,
          action: (function a() {
            return (
              <div className="d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  className="me-3"
                  onClick={() => handleShow(e)}
                >
                  <i className="fa fa-edit me-2"></i> Editar
                </Button>
                {enabled ? (
                  <Button
                    className="me-3"
                    variant="outline-danger"
                    onClick={() => changeState(e, "Deshabilitar")}
                  >
                    <i className="fa fa-times me-2"></i> Deshabilitar
                  </Button>
                ) : (
                  <Button
                    className="me-3"
                    variant="outline-success"
                    onClick={() => changeState(e, "Habilitar")}
                  >
                    <i className="fa fa-check me-2"></i> Habilitar
                  </Button>
                )}
                <Button
                  className="me-3"
                  variant="outline-info"
                  onClick={() => handleActivity(e)}
                >
                  <i className="fa fa-bus me-2"></i> Actividad
                </Button>
              </div>
            );
          })(),
        };
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleDates = (e) => {
    if (e.target.name === "fecha_desde") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleHoraries = (e) => {
    if (e) {
      setHorary(e.value);
    } else {
      setHorary("");
    }
  };

  const handleChange = (e) => {
    setEnabled(e?.target?.value);
  };

  const handleActivityFilter = (e) => {
    if (e) {
      setActivityFilter(e.value);
    } else {
      setActivityFilter("");
    }
  };

  return (
    <div>
      <div>
        <Row className="mt-4 mb-3">
          <Col>
            <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
              Fecha desde:
            </Form.Label>
            <Form.Control
              name="fecha_desde"
              type="date"
              onChange={handleDates}
              required
            />
          </Col>
          <Col>
            <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
              Fecha hasta:
            </Form.Label>
            <Form.Control
              name="fecha_hasta"
              type="date"
              onChange={handleDates}
              required
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Actividad
            </FormLabel>
            <ReactSelect
              options={
                activities?.length
                  ? [emptyOption, ...activities]
                  : [emptyOption]
              }
              name="actividad"
              onChange={handleActivityFilter}
              value={
                activityFilter
                  ? { label: activityFilter, value: activityFilter }
                  : ""
              }
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Horarios
            </FormLabel>
            <ReactSelect
              options={avariableHorary}
              name="grados"
              onChange={handleHoraries}
              value={horary ? { label: horary, value: horary } : ""}
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
          <Col>
            <FormLabel style={{ fontSize: 18, fontWeight: 600 }}>
              Disponible
            </FormLabel>
            <FormControl
              as={"select"}
              name="disponible"
              onChange={handleChange}
            >
              <option value={""}>Seleccionar</option>
              <option value={"1"}>Si</option>
              <option value={"0"}>No</option>
            </FormControl>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mb-5">
          <Button variant="outline-primary" onClick={getDates}>
            Actualizar
          </Button>
        </div>
      </div>
      <Table
        dataSource={formatData(showData)}
        columns={columns.turns}
        rowKey={(row) => row?.id}
      />

      {showEdit ? (
        <EditTurns
          show={showEdit}
          handleClose={handleClose}
          data={editData}
          getDates={getDates}
        />
      ) : (
        ""
      )}
      {show ? (
        <ChangeActivity
          show={show}
          handleClose={() => setShow(false)}
          data={activity}
          setData={setData}
          getData={getDates}
          table="turns"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TurnsTable;
