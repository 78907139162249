import { Col, Form, Row } from "react-bootstrap";

import { cargos } from "../../constants/form";
import Select from "../select/Select";

import "../../assets/styles/Forms.css";

const Representative = ({ register, control, errors /*,check*/ }) => {
  return (
    <div className="step-form">
      <div className="text-center">
        <h6>Datos del representante</h6>
      </div>
      <div className="forms">
        <Form.Group>
          <Row>
            <Col sm={12} md={6}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                name="name"
                {...register("name", { required: true })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Col>
            <Col className="mt-3 md:!mt-0" sm={12} md={6}>
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                name="lastname"
                {...register("lastname", { required: true })}
                isInvalid={!!errors.lastname}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastname?.message}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Label>DNI</Form.Label>
              <Form.Control
                name="dni"
                type="number"
                {...register("dni", {
                  required: true,
                  maxLength: 8,
                  minLength: 8,
                })}
                isInvalid={!!errors.dni}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dni?.message}
              </Form.Control.Feedback>
            </Col>
            <Col className="mt-3 md:!mt-0" sm={12} md={6}>
              <Form.Label>Cargo</Form.Label>
              <Select
                control={control}
                name={"cargo"}
                options={cargos}
                className={`${errors.cargo ? "is-invalid" : ""}`}
                isClearable
              />
              <Form.Control.Feedback type="invalid">
                {errors.cargo?.message}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Label>Celular</Form.Label>
              <Form.Control
                name="mobilephone"
                type="number"
                {...register("mobilephone", {
                  required: true,
                  maxLength: 14,
                  minLength: 6,
                })}
                isInvalid={!!errors.mobilephone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobilephone?.message}
              </Form.Control.Feedback>
            </Col>
            <Col className="mt-3 md:!mt-0" sm={12} md={6}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                {...register("email", { required: true })}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
      </div>
    </div>
  );
};

export default Representative;
