import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { logout } from "../../services/auth";

import ReactSelect from "react-select";
import { formatLocalDate } from "../../utils/utils";
import { useEffect } from "react";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";

const ChangeActivity = ({ show, handleClose, data, getData, table }) => {
  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateTurnosAPI = useAxiosTurnosAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");

  useEffect(() => {
    const getData = async () => {
      if (table === "grades") {
        let response = await axios.get("activities");

        let result = response.data.map((e) => {
          return {
            ...e,
            value: e?.id,
            label: e?.actividad,
          };
        });

        setActivities(result);
      } else {
        let response = await axios.get("restrictions");

        let result = response.data.map((e) => {
          return {
            ...e,
            value: e?.id,
            label: e?.unidad?.name,
          };
        });

        setActivities(result);
      }
    };

    getData();
  }, [table]);

  const handleChange = (e) => {
    console.log(e);
    setActivity(e);
  };

  const submit = () => {
    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar turnos</h3>`,
      html:
        table === "grades"
          ? `<div>
          <p>¿Esta seguro de estos cambios?</p>
          <p><span style="font-weight:600;">Actividad anterior: ${data?.actividades?.actividad}</span> </p>
          <p><span style="font-weight:600;">Nueva actividad: ${activity?.actividad}</span> </p>
        </div>`
          : `<div>
          <p>¿Esta seguro de estos cambios?</p>
          <p><span style="font-weight:600;">Actividad anterior: ${data?.restriccion?.unidad?.name}</span> </p>
          <p><span style="font-weight:600;">Nueva actividad: ${activity?.unidad?.name}</span> </p>
        </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));
          let response;

          if (table === "grades") {
            response = await axiosPrivate.put("/grades/editGrade", {
              id: data?.id,
              actividadId: activity?.value,
              id_usuario: user?.id,
              prevActivity: data?.actividades?.actividad,
              nextActivity: activity?.actividad,
            });
          } else {
            response = await axiosPrivateTurnosAPI.put("/restrictions/change", {
              id: data?.id,
              newId: activity?.value,
              id_usuario: user?.id,
              prevActivity: data?.restriccion?.unidad?.name,
              nextActivity: activity?.unidad?.name,
            });
          }

          if (response.status === 200) {
            getData();

            dispatch(setLoading(false));

            return Swal.fire({
              title: "Exito!",
              text: "La actividad fue modifica exitosamente",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            return Swal.fire({
              title: "Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="p-5">
        <h4 className="text-center">Cambiar Actividad</h4>

        {table === "turns" ? (
          <Row className="my-4">
            <Col className="fs-6">
              <span>Turno:</span>{" "}
              <span className="fw-bolder">
                {formatLocalDate(data?.fecha)} - {data?.horario}
              </span>
              <br />
              <span>Actividad Actual:</span>{" "}
              <span className="fw-bolder">
                {data?.restriccion?.unidad?.name}
              </span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col className="mt-3">
            <Form.Label className="label">
              Actividades <span className="text-red">*</span>
            </Form.Label>

            <ReactSelect
              options={activities}
              onChange={handleChange}
              styles={stylesReactSelect}
              isClearable
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-center mt-5">
          <Button
            variant="outline-primary"
            type="submit"
            className="me-3"
            onClick={submit}
          >
            Cambiar
          </Button>
          <Button variant="outline-danger" onClick={handleClose}>
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeActivity;
