import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import ReactSelect from "react-select";
import { axiosTurnosAPI } from "../../api/axios";
import { formatLocalDate, setDatesClassName } from "../../utils/utils";
import "./PreviewCalendar.css";

const PreviewCalendar = () => {
  const [day, setDay] = useState("");
  const [horary, setHorary] = useState([]);
  const [turn, setTurn] = useState({});

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const getDates = async () => {
      let response = await axiosTurnosAPI.get("/turns/getDatesData");
      if (response.status === 200) {
        setDates(response.data);
      } else {
        setDates([]);
      }
    };

    getDates();

    return () => {
      setDates([]);
    };
  }, []);

  useEffect(() => {
    const getHorary = async () => {
      let response = await axiosTurnosAPI.get(
        `/turns/horaryByDate?fecha=${day ? day : ""}`
      );
      if (response.status === 200) {
        if (response.data?.length) {
          setHorary(
            response.data.map((e) => {
              return {
                ...e,
                value: e.id,
                label: `${e.horario} - ${e.restriccion?.unidad?.name} -> ${
                  !e.enabled ? "DESHABILITADO" : "HABILITADO"
                }`,
              };
            })
          );
        } else {
          setHorary([]);
        }
      } else {
        setHorary([]);
      }
    };

    getHorary();

    return () => {
      setHorary([]);
    };
  }, [day]);

  return (
    <div className="mb-5 bg-white rounded preview-calendar">
      <Form.Group>
        <Row className="mb-3">
          <Col md={6} className="">
            <div className="text-center">
              <p className="fs-5">Seleccionar dia</p>
            </div>

            <Calendar
              onChange={(value) => {
                setTurn("");
                setDay(format(new Date(value), "yyyy-MM-dd"));
                return value;
              }}
              tileClassName={({ date }) =>
                date ? setDatesClassName(date, dates) : ""
              }
              allowPartialRange
            />
            <div style={{ fontSize: 18, marginTop: 20 }}>
              <div className="d-flex ">
                <div
                  className="square me-3"
                  style={{ backgroundColor: "#08ad08" }}
                ></div>
                <div>
                  <span style={{ fontWeight: 600 }}>Habilitados</span> (Turnos
                  que estan disponibles para los usuarios)
                </div>
              </div>
              <div className="d-flex ">
                <div
                  className="square me-3"
                  style={{ backgroundColor: "#d10000" }}
                ></div>
                <div>
                  <span style={{ fontWeight: 600 }}>Deshabilitados</span>{" "}
                  (Turnos creados pero no habilitados para los usuarios)
                </div>
              </div>
            </div>
          </Col>

          {day ? (
            <>
              <Col className="ps-3 mt-5">
                <div className="text-center">
                  <p className="fs-5">Seleccionar horario</p>
                </div>
                <ReactSelect
                  options={horary}
                  value={turn ? turn : ""}
                  onChange={(value) => {
                    return setTurn(value);
                  }}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      </Form.Group>
      {day ? (
        <div className="turn-data">
          <div className="turn-info">
            <span>Fecha seleccionada</span>
            {turn ? <span>Horario</span> : ""}
            {turn ? <span>Actividad</span> : ""}
            {turn ? <span>Turnos disponibles</span> : ""}
            {turn ? <span>Estado del turno</span> : ""}
          </div>
          <div className="turn-info-values">
            <span>{formatLocalDate(day)}</span>
            {turn ? <span>{turn.horario}</span> : ""}
            {turn ? <span>{turn.restriccion?.unidad?.name}</span> : ""}
            {turn ? <span>{turn?.disponibles}</span> : ""}
            {turn ? (
              <span>
                {turn?.enabled ? (
                  <span style={{ color: "green" }}>Activado</span>
                ) : (
                  <span style={{ color: "red" }}>Desactivado</span>
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewCalendar;
