import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser"

const PrivateRouter = ({children}) => {  
  const user = useUser();

  if (user) {
    return children;
  }
  else {
    return <Navigate to={("/")}/>;
  }
}

export default PrivateRouter