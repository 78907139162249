import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { logout } from "../../services/auth";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import useUser from "../../hooks/useUser";

import ReactSelect from "react-select";
import { stylesReactSelect } from "../select/styles";
import { getUnidadSemanas, getWeekActivities } from "../../services/activities";
import axios from "../../api/axios";

const emptyOption = { value: "", label: "Seleccionar" };
const nullOption = { value: "ninguno", label: "Ninguno" };

const EditTurnDate = ({
  show,
  handleClose,
  date,
  setData,
  actividades,
  semana,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [prevDias, setPrevDias] = useState([]);

  const [values, setValues] = useState({
    actividad: date.id,
    dia: "",
    prevDia: "",
  });

  useEffect(() => {
    if (date?.Unidad_Semanas.length) {
      setPrevDias(
        date.Unidad_Semanas.map((e) => {
          return {
            ...e,
            value: e.idSemana,
            label: e.Semana.dia,
          };
        })
      );
    } else {
      setPrevDias([
        {
          value: null,
          label: "No poseé dias asignados.",
        },
      ]);
    }
  }, []);

  const handleChange = (e, type) => {
    if (type === "old") {
      setValues({
        ...values,
        prevDia: e.value,
      });
    } else {
      setValues({
        ...values,
        dia: e.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.actividad || !values.dia || !values.prevDia)
      return Swal.fire({
        text: "Debe completar todos los campos.",
        icon: "warning",
      });

    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar actividad</h3>`,
      html: `<div>
        <p>¿Esta seguro de estos cambios?</p>
      </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));
          let response = await axios.post("units/setUnidadSemana", values);

          if (response.status === 200) {
            const response = await getUnidadSemanas();

            setData(response);
            dispatch(setLoading(false));

            return Swal.fire({
              title: "¡Éxito!",
              text: "El turno fue modificado exitosamente.",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            dispatch(setLoading(false));
            return Swal.fire({
              title: "¡Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión.",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text:
            err.response.data ||
            "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="change-activity"
    >
      <Modal.Body className="p-5">
        <div>
          <h5 className="form-subtitle mb-2 mt-3">Actividad</h5>
          <Row>
            <ReactSelect
              options={
                actividades?.length
                  ? [emptyOption, ...actividades]
                  : [emptyOption]
              }
              styles={stylesReactSelect}
              onChange={handleChange}
              defaultValue={
                date.name
                  ? actividades.find((e) => e.name === date.name)
                  : actividades[0]
              }
              isDisabled
            />
          </Row>
        </div>
        <div>
          <h5 className="form-subtitle mb-2 mt-3">Cambiar dia</h5>
          <Row>
            <ReactSelect
              options={
                prevDias?.length ? [emptyOption, ...prevDias] : [emptyOption]
              }
              styles={stylesReactSelect}
              onChange={(e) => handleChange(e, "old")}
              defaultValue={emptyOption}
            />
          </Row>
        </div>
        <div>
          <h5 className="form-subtitle mb-2 mt-3">Seleccionar nueva dia</h5>
          <Row>
            <ReactSelect
              options={
                semana?.length
                  ? [emptyOption, ...semana, nullOption]
                  : [emptyOption]
              }
              styles={stylesReactSelect}
              onChange={(e) => handleChange(e, "new")}
              defaultValue={emptyOption}
            />
          </Row>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button
            variant="outline-primary"
            onClick={handleSubmit}
            className="me-3"
          >
            Cambiar
          </Button>
          <Button variant="outline-danger" onClick={handleClose}>
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditTurnDate;
