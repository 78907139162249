import { useEffect, useState } from "react";
import { Col, Form, FormCheck, Row } from "react-bootstrap";
import axios from "../../api/axios";
import Select from "../select/Select";
import { useDispatch } from "react-redux";
import { setLoading, setSchool } from "../../redux/formDataSlice";
import GenericModal from "../modal/generic-modal.jsx";
import SchoolForm from "../school-form/school-form";
import Swal from "sweetalert2";

const School = ({ control, errors, setValue, getValues, watch, register }) => {
  const dispatch = useDispatch();
  const isLocalWatch = watch("isLocal");
  const id_provincia = watch("id_provincia");
  const id_localidad = watch("id_localidad");
  const [locations, setLocations] = useState([]);

  const [locals, setLocals] = useState([]);
  const [noLocals, setNoLocals] = useState([]);

  const [exist, setExist] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState("Seleccionar");

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setExist((prev) => !prev);
    setShowModal(false);
  };
  const getData = async () => {
    let [response, { data: locations }] = await Promise.all([
      axios.get("schools"),
      axios.get("locations"),
    ]);
    const localsArray = [];
    const noLocalsArray = [];

    response.data.forEach((e) => {
      const formattedData = {
        ...e,
        label: e.nombre,
        value: e.nombre,
      };
      if (e.isLocal === true) {
        localsArray.push(formattedData);
      } else {
        noLocalsArray.push(formattedData);
      }
    });

    setLocals(localsArray);
    setNoLocals(noLocalsArray);
    setLocations(locations.data);
  };

  const handleSubmitSchool = (data) => {
    try {
      dispatch(setLoading(true));
      axios
        .post("/schools", data)
        .then(async (response) => {
          await getData();
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Éxito!",
            text: `${response.data.message || "Operacion completa"}`,
            icon: "success",
          });
        })
        .catch((err) => {
          console.error("axios catch => ", err);
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Error!",
            text: `${
              err.response.data.message ||
              "Ocurrio un error, intentelo nuevamente"
            }`,
            icon: "error",
          });
        });
    } catch (err) {
      console.error("try catch -> ", err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(setSchool({}));
    setSelectedSchool("Seleccionar");
    setValue("id_provincia", {
      label: "Seleccionar",
      value: null,
    });
    setValue("id_localidad", {
      label: "Seleccionar",
      value: null,
    });
    setValue("name_school", "Seleccionar");
  }, [dispatch, isLocalWatch, setValue, watch]);

  const handleChange = (e) => {
    if (e.value === "notfound") {
      setExist(false);
      handleShow(true);
      return;
    } else {
      setExist(true);
      handleClose(false);
    }
    dispatch(setSchool(e));
    // seteo estado local y en label del select
    setSelectedSchool({ ...e, name_school: e.value });
    setValue("name_school", e.value);
    if (isLocalWatch === "true" || isLocalWatch === true)
      setValue("otro_school", false);
    else if (isLocalWatch === "false" || isLocalWatch === false)
      setValue("otro_school", true);
  };

  const handleProvinciaChange = (e) => {
    setValue("id_localidad", {
      label: "Seleccionar",
      value: null,
    });
    setValue("name_school", "Seleccionar");
    setSelectedSchool("Seleccionar");
    setValue("id_provincia", e);
  };

  const handleLocalidadChange = (e) => {
    setValue("name_school", "Seleccionar");
    setSelectedSchool("Seleccionar");
    setValue("id_localidad", e.value);
  };

  const schoolData = getValues();

  return (
    <div className="step-form">
      <div className="text-center">
        <h6>Datos de la escuela</h6>
      </div>
      <div className="forms">
        <div className="flex flex-row gap-x-3 mb-4">
          <h3>¿Es una escuela local?</h3>
          <FormCheck
            type="radio"
            {...register("isLocal")}
            value={true}
            label="SI"
            checked={isLocalWatch === "true" || isLocalWatch === true}
          />

          <FormCheck
            type="radio"
            {...register("isLocal")}
            value={false}
            label="NO"
            checked={isLocalWatch === "false" || isLocalWatch === false}
          />
        </div>
        {isLocalWatch && (
          <Form.Group>
            {isLocalWatch === "true" ? (
              <Row className="">
                <Col>
                  <Form.Label>Escuela</Form.Label>
                  <Select
                    name="name_school"
                    control={control}
                    defaultValue={selectedSchool}
                    options={
                      locals?.length > 0
                        ? [
                            ...locals,
                            {
                              label: "- No encuentro mi escuela",
                              value: "notfound",
                            },
                          ]
                        : [
                            {
                              label: "- No se encontraron escuelas -",
                              value: "otro",
                            },
                          ]
                    }
                    value={selectedSchool}
                    className={`${errors.name_school ? "is-invalid" : ""}`}
                    handleChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_school?.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form.Label>Provincia</Form.Label>
                  <Select
                    control={control}
                    name="id_provincia"
                    handleChange={handleProvinciaChange}
                    value={id_provincia}
                    options={locations?.map((el) => ({
                      label: el.nombre,
                      value: el.id,
                    }))}
                  />
                  {id_provincia.value && (
                    <>
                      <Form.Label>Localidad</Form.Label>
                      <Select
                        control={control}
                        name="id_localidad"
                        options={locations
                          ?.filter((el) => el.id === id_provincia.value)
                          .flatMap((obj) =>
                            obj.localidades
                              .filter(
                                (localidad) =>
                                  localidad.nombre !== "San Nicolas"
                              )
                              .sort((a, b) => a.nombre.localeCompare(b.nombre))
                              .map((localidad) => ({
                                label: localidad.nombre,
                                value: localidad.id,
                              }))
                          )}
                        handleChange={handleLocalidadChange}
                        value={id_localidad?.value}
                      />
                    </>
                  )}
                  {id_localidad && !id_localidad?.label && (
                    <>
                      <Form.Label>Escuela</Form.Label>
                      <Select
                        name="name_school"
                        control={control}
                        options={
                          noLocals?.length > 0
                            ? [
                                ...noLocals.filter((escuela) => {
                                  if (
                                    escuela.id_localidad === id_localidad &&
                                    escuela.id_provincia === id_provincia.value
                                  ) {
                                    return escuela;
                                  } else {
                                    return "";
                                  }
                                }),
                                {
                                  label: "- No encuentro mi escuela",
                                  value: "notfound",
                                },
                              ]
                            : [
                                {
                                  label: "- No encuentro mi escuela",
                                  value: "notfound",
                                },
                              ]
                        }
                        value={selectedSchool}
                        className={`${errors.name_school ? "is-invalid" : ""}`}
                        handleChange={handleChange}
                      />
                      {!exist && (
                        <GenericModal
                          title={
                            "Carga los siguientes datos para registrar la escuela"
                          }
                          FormComponent={({ setSubmit }) => (
                            <SchoolForm
                              schoolData={schoolData}
                              handleSubmitSchool={handleSubmitSchool}
                              setSubmit={setSubmit} // Aquí es donde pasas setSubmit a SchoolForm
                            />
                          )}
                          onHide={handleClose}
                          show={showModal}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Form.Group>
        )}
      </div>
    </div>
  );
};

export default School;
