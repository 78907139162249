import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { v4 as uuidv4 } from "uuid";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { logout } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { formatLocalDate } from "../../utils/utils";
import EditReserves from "../editReserves";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import { axiosTurnosAPI } from "../../api/axios";
import { turnsFormat } from "../../utils/turnsFormat";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";

const ReservesTable = ({ data, setData, submitRef }) => {
  const axiosPrivateTurnosAPI = useAxiosTurnosAPI();
  const navigate = useNavigate();
  const user = useUser();
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(setLoading(true));
        let response = await axiosTurnosAPI.post("/reserves/getReserves");

        let result = response.data
          ?.map((element) => {
            return turnsFormat(element?.Reservas, element);
          })
          .flat();

        setData(result);
        dispatch(setLoading(false));
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
      }
    };

    getData();
    return () => {
      setData([]);
    };
  }, []);

  const handleShow = (data) => {
    setEditData(data);
    return setShowEdit(true);
  };
  const handleClose = () => setShowEdit(false);

  const deleteReserves = async (data) => {
    if (data) {
      return Swal.fire({
        title: `<h3 style="color:#dc3741;">Eliminar turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de eliminar este turno?</p>
          <p><span style="font-weight:600;">Escuela:</span> ${
            data?.agent?.agente_entidad?.entidad?.nombre
              ? data?.agent?.agente_entidad?.entidad?.nombre
              : ""
          }</p> 
          <p><span style="font-weight:600;">Grado:</span> ${
            data.grado ? data.grado : ""
          }</p>
          <p><span style="font-weight:600;">Fecha:</span> ${
            data.fecha ? formatLocalDate(data.fecha) : ""
          } a las ${data.horario ? data.horario : ""}</p>
          <p><span style="font-weight:600;">Estudiantes:</span> ${
            data?.personas ? data?.personas : "0"
          }</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            dispatch(setLoading(true));
            let response = await axiosPrivateTurnosAPI.post(
              "/reserves/deleteReserves",
              {
                id: data.id,
                idTurn: data.idEvento_apps_subApps,
                id_usuario: user?.id,
                reserva: {
                  fecha: formatLocalDate(data?.fecha),
                  horario: data.horario,
                  actividad: data?.restriccion?.unidad?.name,
                  escuela: data?.agent?.agente_entidad?.entidad?.nombre,
                  grado: `${data?.grado}`,
                  division: `${data?.subagents?.division}`,
                  representante: `${data?.agent.nombre} ${data?.agent?.apellido}`,
                  email: data?.agent?.email,
                },
              }
            );

            if (response.status === 200) {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "Se borrarón exitosamente.",
                icon: "success",
                willClose: () => submitRef?.current.click(),
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const cancelReserves = async () => {
    if (data) {
      return Swal.fire({
        title: `<h3 style="color:#dc3741;">Cancelar turno</h3>`,
        html: `<div>
          <p>¿Esta seguro de cancelar este turno?</p>
        </div>`,
        icon: `warning`,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            dispatch(setLoading(true));
            let response = await axiosPrivateTurnosAPI.post(
              "/reserves/cancelReserve",
              {
                id: data.Reservas[0].id,
                idTurn: data.turnId,
                id_usuario: user?.id,
                reserva: {
                  fecha: formatLocalDate(data?.fecha),
                  horario: data.horario,
                  actividad: data?.restriccion?.unidad?.name,
                  escuela: data?.agent?.agente_entidad?.entidad?.nombre,
                  grado: `${data?.grado}`,
                  division: `${data?.subagents?.division}`,
                  representante: `${data?.agent.nombre} ${data?.agent?.apellido}`,
                  email: data?.agent?.email,
                },
              }
            );

            if (response.status === 200) {
              submitRef?.current.click();
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Éxito!",
                text: "Se cancelo exitosamente.",
                icon: "success",
              });
            } else {
              dispatch(setLoading(false));
              return Swal.fire({
                title: "¡Error!",
                text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
                icon: "error",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoading(false));
          if (err.response?.status === 403 || err.response?.status === 401) {
            return Swal.fire({
              title: "¡Error!",
              text: "Su sesión expiro, vuelva a iniciar sesión.",
              icon: "warning",
              willClose: () => {
                logout();
                return navigate("/");
              },
            });
          }
          return Swal.fire({
            title: "¡Error!",
            text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
            icon: "error",
          });
        });
    } else {
      return;
    }
  };

  const formatData = (array) => {
    try {
      return array.map((e) => {
        return {
          ...e,
          escuela: e?.agent?.agente_entidad?.entidad?.nombre,
          estudiantes: e?.reserves?.length,
          fecha: e?.fecha ? formatLocalDate(e?.fecha) : "",
          actividad: e?.restriccion?.unidad?.name,
          action: (function a() {
            return (
              <div className="d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  className="me-3"
                  onClick={() => handleShow(e)}
                >
                  <i className="fa fa-edit me-2"></i> Editar
                </Button>
                {e?.estado === "PENDIENTE" ? (
                  <Button
                    className="me-3"
                    variant="outline-danger"
                    onClick={() => cancelReserves()}
                  >
                    <i className="fa fa-remove me-2"></i> Cancelar
                  </Button>
                ) : (
                  ""
                )}
                <div
                  className="ms-3"
                  onClick={() => deleteReserves(e)}
                  style={{ color: "red", fontSize: 22, cursor: "pointer" }}
                >
                  <i className="fa fa-trash me-2"></i>
                </div>
              </div>
            );
          })(),
        };
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  return (
    <>
      <Table
        columns={columns.reserves}
        dataSource={formatData(data)}
        rowKey={() => uuidv4()}
      />
      {showEdit ? (
        <EditReserves
          show={showEdit}
          handleClose={handleClose}
          data={editData}
          submitRef={submitRef}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ReservesTable;
