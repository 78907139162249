import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios, { axiosTurnosAPI } from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { logout } from "../../services/auth";
import Select from "../select/Select";
import ReactSelect from "react-select";
import { formatLocalDate } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";

const emptyOption = { value: "", label: "Seleccionar" };

const EditReserves = ({ show, handleClose, data, submitRef }) => {
  const { handleSubmit, control, setValue } = useForm();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const [dates, setDates] = useState([]);
  const [horaries, setHoraries] = useState([]);
  const [date, setDate] = useState("");
  const [horary, setHorary] = useState("");
  const [turn, setTurn] = useState("");

  useEffect(() => {
    const getDates = async () => {
      let actividad = data?.restriccion?.unidad?.name ?? "";

      let response = await axiosTurnosAPI.get(
        `/turns/available-dates?actividad=${actividad}`
      );

      let result = response.data.map((e) => {
        return {
          label: formatLocalDate(e),
          value: e,
        };
      });

      result = result?.sort(
        (a, b) => new Date(a?.value).getTime() - new Date(b?.value).getTime()
      );
      setDates(result);
    };

    getDates();
    return () => {};
  }, [data]);

  useEffect(() => {
    if (date) {
      const getDates = async () => {
        let actividad = data?.restriccion?.unidad?.name ?? "";

        let response = await axiosTurnosAPI.get(
          `/turns/horary?fecha=${date}&&actividad=${actividad}`
        );

        let result = response.data.map((e) => {
          return {
            ...e,
            label: e.horario,
            value: e.horario,
          };
        });
        setHoraries(result);
      };

      getDates();

      setTurn("");
      setHorary("");
      setValue("horario", "");

      return () => {
        setHoraries([]);
      };
    }
  }, [date, data, setValue]);

  const handleChange = (e) => {
    if (e) {
      setDate(e.value);
    } else {
      setDate("");
    }
  };

  const handleTurn = (e) => {
    setTurn(e);
    setHorary(e);
  };

  const submit = (values) => {
    if (!values.fecha || !values.horario || !turn)
      return Swal.fire(
        "Incompleto",
        "Debe elegir una fecha y horario",
        "warning"
      );
    if (data?.reserves?.length > turn.disponibles)
      return Swal.fire(
        "Turnos insuficientes",
        "No hay suficientes turnos en esta fecha",
        "warning"
      );

    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar turnos</h3>`,
      html: `<div>
        <p>¿Esta seguro de estos cambios?</p>
        <p><span style="font-weight:600;">Escuela:</span> ${
          data?.agent?.agente_entidad?.entidad?.nombre
            ? data?.agent?.agente_entidad?.entidad?.nombre
            : ""
        }</p>
        <p><span style="font-weight:600;">Grado:</span> ${
          data.grado ? data.grado : ""
        }</p>
        <p><span style="font-weight:600;">Actividad:</span> ${
          data?.restriccion?.unidad?.name
        }</p>
        <p><span style="font-weight:600;">Anterior fecha:</span> ${
          data.fecha ? formatLocalDate(data.fecha) : ""
        } a las ${data.horario ? data.horario : ""}</p>
        <p><span style="font-weight:600;">Nueva fecha:</span> ${formatLocalDate(
          values.fecha
        )} a las ${values.horario}</p>
      </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));
          let response = await axiosTurnosAPI.patch("/reserves/changeTurn", {
            id: data.id,
            idTurn: data.idEvento_apps_subApps,
            newTurn: turn.id,
            id_usuario: user?.id,
            prevTurn: `${data.fecha ? formatLocalDate(data.fecha) : ""} a las ${
              data.horario ? data.horario : ""
            }`,
            nextTurn: `${formatLocalDate(values.fecha)} a las ${
              values.horario
            }`,
            reserva: {
              actividad: data?.restriccion?.unidad?.name,
              escuela: data?.agent?.agente_entidad?.entidad?.nombre,
              grado: `${data?.subagents?.nombre}`,
              division: `${data?.subagents?.division}`,
              representante: `${data?.agent?.nombre} ${data?.agent?.apellido}`,
              email: data?.agent?.email,
            },
          });

          if (response.status === 200) {
            submitRef?.current?.click();
            dispatch(setLoading(false));

            return Swal.fire({
              title: "¡Éxito!",
              text: "El turno fue modificado exitosamente.",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            dispatch(setLoading(false));
            return Swal.fire({
              title: "¡Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión.",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="p-5">
        <h4 className="text-center">Editar fecha y horario</h4>
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Group>
            <Row>
              <Col className="mt-3">
                <Form.Label className="label">
                  Fecha <span className="text-red">*</span>
                </Form.Label>
                <Select
                  control={control}
                  name="fecha"
                  options={
                    dates?.length ? [emptyOption, ...dates] : [emptyOption]
                  }
                  isClearable={true}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
            {date ? (
              <Row>
                <Col className="mt-3">
                  <Form.Label className="label">
                    Horario <span className="text-red">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="horario"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactSelect
                          options={
                            horaries?.length
                              ? [emptyOption, ...horaries]
                              : [emptyOption]
                          }
                          onChange={(e) => {
                            handleTurn(e);
                            return onChange(e?.value);
                          }}
                          value={horary}
                          styles={stylesReactSelect}
                          isClearable
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Form.Group>

          <div className="d-flex justify-content-center mt-5">
            <Button variant="outline-primary" type="submit" className="me-3">
              Cambiar
            </Button>
            <Button variant="outline-danger" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditReserves;
