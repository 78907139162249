import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const GenericModal = ({ show, onHide, title, FormComponent, footer }) => {
  const [submitForm, setSubmitForm] = useState(null); // Estado para almacenar handleSubmit

  const handleConfirmClick = async () => {
    if (submitForm) {
      try {
        const result = await submitForm(); // Invoca el handleSubmit pasado desde SchoolForm
        if (!result) {
          // Si hay errores, no cerrar el modal
          return;
        }
        onHide(); // Cierra el modal si la función de envío fue exitosa
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
      }
    } else {
      console.warn("No se configuró submitForm correctamente.");
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} centered>
      <Modal.Header className="d-flex justify-content-center" closeButton>
        <Modal.Title className="modalTitle">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <FormComponent setSubmit={setSubmitForm} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center mt-3">
        {footer ? (
          footer
        ) : (
          <>
            <Button
              variant="secondary"
              className="mt-3 mb-3 bg-[#0d6efd]"
              onClick={onHide}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="mt-3 mb-3 bg-[#dc3545]"
              onClick={handleConfirmClick}
            >
              Confirmar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
