import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import { logout } from "../../services/auth";

import ReactSelect from "react-select";
import { formatLocalDate } from "../../utils/utils";
import { avariableHorary } from "../../constants/form";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/formDataSlice";
import useUser from "../../hooks/useUser";
import { stylesReactSelect } from "../select/styles";
import useAxiosTurnosAPI from "../../hooks/useAxiosTurnosAPI";

const EditTurns = ({ show, handleClose, data, getDates }) => {
  const { handleSubmit, control, register } = useForm();
  const axiosPrivateTurnosAPI = useAxiosTurnosAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const submit = (values) => {
    if (!values.fecha || !values.horario)
      return Swal.fire(
        "Incompleto",
        "Debe elegir una fecha y horario",
        "warning"
      );

    return Swal.fire({
      title: `<h3 style="color:#0d6efd;">Modificar turnos</h3>`,
      html: `<div>
        <p>¿Esta seguro de estos cambios?</p>
        <p><span style="font-weight:600;">Escuela:</span> ${
          data?.agent?.agente_entidad?.entidad?.nombre
            ? data?.agent?.agente_entidad?.entidad?.nombre
            : ""
        } - <span style="font-weight:600;">Grado:</span> ${
        data.grado ? data.grado : ""
      }</p>
        <p><span style="font-weight:600;">Anterior fecha:</span> ${
          data.fecha ? formatLocalDate(data.fecha) : ""
        } a las ${data.horario ? data.horario : ""}</p>
        <p><span style="font-weight:600;">Nueva fecha:</span> ${
          values.fecha ? formatLocalDate(values.fecha) : ""
        } a las ${values.horario ? values.horario : ""}</p>
      </div>`,
      icon: `warning`,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "green",
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          dispatch(setLoading(true));
          let response = await axiosPrivateTurnosAPI.put("/turns/edit", {
            ...values,
            id: data?.id,
            id_usuario: user?.id,
            prevTurn: `${data.fecha ? formatLocalDate(data.fecha) : ""} a las ${
              data.horario ? data.horario : ""
            }`,
            nextTurn: `${
              values.fecha ? formatLocalDate(values.fecha) : ""
            } a las ${values.horario ? values.horario : ""}`,
          });

          if (response.status === 200) {
            dispatch(setLoading(false));
            getDates();

            return Swal.fire({
              title: "¡Éxito!",
              text: "Los turnos fueron modificados exitosamente.",
              icon: "success",
              willClose: () => handleClose(),
            });
          } else {
            return Swal.fire({
              title: "¡Error!",
              text: "Ocurrio un error durante el proceso, intentelo nuevamente.",
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          dispatch(setLoading(false));
          return Swal.fire({
            title: "¡Error!",
            text: "Su sesión expiro, vuelva a iniciar sesión.",
            icon: "warning",
            willClose: () => {
              logout();
              return navigate("/");
            },
          });
        }
        dispatch(setLoading(false));

        return Swal.fire({
          title: "Error!",
          text: "Ocurrio un error durante el proceso, intentelo nuevamente",
          icon: "error",
        });
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      {/* {!loading ? */}
      <Modal.Body className="p-5">
        <h4 className="text-center">Editar fecha y horario</h4>
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Group>
            <Row>
              <Col className="mt-3">
                <Form.Label className="label">
                  Fecha <span className="text-red">*</span>
                </Form.Label>
                <Form.Control
                  name="fecha"
                  type="date"
                  defaultValue={data.fecha}
                  {...register("fecha")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <Form.Label className="label">
                  Horario <span className="text-red">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="horario"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ReactSelect
                        options={avariableHorary}
                        onChange={(e) => {
                          return onChange(e?.value);
                        }}
                        styles={stylesReactSelect}
                        isClearable
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </Form.Group>

          <div className="d-flex justify-content-center mt-5">
            <Button variant="outline-primary" type="submit" className="me-3">
              Cambiar
            </Button>
            <Button variant="outline-danger" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* //   :
      //   <div className='p-4'>
      //     <div className='d-flex justify-content-center align-items-center m-4'>
      //       <h2>Cargando...</h2>
      //     </div>
      //     <div className='d-flex justify-content-center align-items-center m-5'>
      //       <Spinner animation="border" className="loading-spinner" />
      //     </div>
      //   </div>
      // } */}
    </Modal>
  );
};

export default EditTurns;
