import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'

const LoadingModal = ({show, handleClose}) => {
  return (
    <Modal className='loading-modal' show={show} onHide={handleClose} animation={false} keyboard={false} centered>
      <Modal.Body>
        <div>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <h2>Cargando...</h2>
          </div>
          <div className='d-flex justify-content-center align-items-center m-5'>
            <Spinner animation="border" className="loading-spinner" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal