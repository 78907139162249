import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { axiosTurnosAPI } from "../../api/axios";

const TurnsAdmin = () => {
  const [values, setValues] = useState({
    fecha_desde: "",
    fecha_hasta: "",
    enabled: "1",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        (!values.enabled && values.enabled !== 0) ||
        !values.fecha_desde ||
        !values.fecha_hasta
      )
        return Swal.fire(
          "Incompleto!",
          "Debe completar todos los campos solicitados.",
          "error"
        );

      values.enabled = +values.enabled;
      let response = await axiosTurnosAPI.post("/turns/editTurns", values);

      if (response.status === 200) {
        Swal.fire(
          "Exito!",
          "Los turnos fueron modificados correctamente.",
          "success"
        );
      } else {
        Swal.fire("Error!", "Ocurrio un error, intetelo nuevamente.", "error");
      }
    } catch (err) {
      Swal.fire("Error!", "Ocurrio un error, intetelo nuevamente.", "error");
    }
  };

  return (
    <div>
      <div className="mt-4">
        <h4>Habilitar/Deshabilitar turnos</h4>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mt-3">
          <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
            Fecha desde:
          </Form.Label>
          <Form.Control
            name="fecha_desde"
            type="date"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
            Fecha hasta:
          </Form.Label>
          <Form.Control
            name="fecha_hasta"
            type="date"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label style={{ fontSize: 18, fontWeight: 600 }}>
            Acción:
          </Form.Label>
          <Form.Control
            name="enabled"
            as={"select"}
            onChange={handleChange}
            defaultValue={1}
            required
          >
            <option value={""}>Seleccionar...</option>
            <option value={1}>Habilitar</option>
            <option value={0}>Deshabilitar</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="d-flex justify-content-center mt-4">
          <Button variant="outline-success" type="submit">
            Enviar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default TurnsAdmin;
